// src/components/TimelineViewer/TimelinePicker.tsx

import React from "react";
import Select, { StylesConfig, SingleValue } from "react-select";

// Define la interfaz para las opciones
export interface Option {
  value: string;
  label: string;
}

// Define las props que recibirá TimelinePicker
interface TimelinePickerProps {
  options: Option[];
  selectedOption: Option | null;
  onChange: (selected: SingleValue<Option>) => void;
}

// Define los estilos personalizados
const customSelectStyles: StylesConfig<Option, false> = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    maxWidth: "90vw",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    borderColor: "#000",
    // borderRadius: "9999px",
    borderRadius: "0px",
    minHeight: "32px",
    height: "32px",
    padding: state.isMulti && state.hasValue ? "0 4px" : "0 12px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    borderWidth: "0px",
    borderBottomWidth: "1px",
    color: "#000",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    color:
      state.isMulti || !state.hasValue
        ? "rgba(0, 0, 0, 0.6)"
        : "rgba(255, 255, 255, 0.6)",
    display: "flex",
    alignItems: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000",
    display: "flex",
    alignItems: "center",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0",
    marginLeft: "4px",
    alignItems: "center",
    display: "flex",
    color: state.isMulti ? "#000" : "#fff",
    cursor: "pointer",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "0",
    color: "#000",
    boxShadow: "none",
    left: "50%",
    transform: "translateX(-50%)",
    width: "max-content",
    minWidth: "100%", // Puedes ajustar este tamaño según sea necesario
    maxWidth: "90vw",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#000" : "transparent",
    color: state.isFocused ? "#fff" : "#000",
    "&:active": {
      backgroundColor: "#000",
      color: "#fff",
    },
  }),
};

// Componente TimelinePicker
const TimelinePicker: React.FC<TimelinePickerProps> = ({
  options,
  selectedOption,
  onChange,
}) => {
  return (
    <Select
      styles={customSelectStyles}
      options={options}
      value={selectedOption}
      onChange={onChange}
      isMulti={false}
      placeholder={"Selecciona un timeline"}
      isClearable={false}
      isSearchable={false}
    />
  );
};

export default TimelinePicker;
