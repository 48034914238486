import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Definir la interfaz para el contexto
interface ReadingModeContextProps {
  isReadingMode: boolean;
  toggleReadingMode: () => void;
}

// Crear el contexto
const ReadingModeContext = createContext<ReadingModeContextProps | undefined>(
  undefined
);

// Provider para envolver la aplicación
export const ReadingModeProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Leer el valor del query param 'readingMode'
  const queryParams = new URLSearchParams(location.search);
  const initialMode = queryParams.get("readingMode") === "true";

  const [isReadingMode, setIsReadingMode] = useState(initialMode);

  const toggleReadingMode = () => {
    setIsReadingMode((prev) => {
      const newValue = !prev;
      const newQueryParams = new URLSearchParams(location.search);

      if (newValue) {
        newQueryParams.set("readingMode", "true");
      } else {
        newQueryParams.delete("readingMode"); // Eliminar el parámetro si es falso
      }

      // Actualiza la URL sin recargar la página
      navigate({ search: newQueryParams.toString() }, { replace: true });
      return newValue;
    });
  };

  useEffect(() => {
    // Escucha los cambios de ubicación y agrega el query param solo si está en modo lectura
    const newQueryParams = new URLSearchParams(location.search);
    if (isReadingMode && !newQueryParams.has("readingMode")) {
      newQueryParams.set("readingMode", "true");
      navigate({ search: newQueryParams.toString() }, { replace: true });
    } else if (!isReadingMode && newQueryParams.has("readingMode")) {
      newQueryParams.delete("readingMode");
      navigate({ search: newQueryParams.toString() }, { replace: true });
    }
  }, [isReadingMode, navigate, location.search]);

  return (
    <ReadingModeContext.Provider value={{ isReadingMode, toggleReadingMode }}>
      {children}
    </ReadingModeContext.Provider>
  );
};

// Hook para usar el contexto
export const useReadingMode = () => {
  const context = useContext(ReadingModeContext);
  if (!context) {
    throw new Error(
      "useReadingMode debe ser usado dentro de ReadingModeProvider"
    );
  }
  return context;
};
