import { Tooltip } from "react-tooltip"; // Importar el Tooltip correctamente

const TooltipListener: React.FC<{}> = () => {
  if (/Mobi|Android/i.test(navigator.userAgent)) {
    return null;
  }

  return (
    <Tooltip
      id="tooltip"
      place="top"
      offset={20}
      opacity={1}
      delayShow={500}
      variant="light"
      className="remove-for-screenshot"
      style={{
        borderRadius: "999px",
        zIndex: 9999,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        padding: "0.33rem 1rem",
      }}
    />
  );
};

export default TooltipListener;
