// src/components/TimelinePage.tsx

import React, { useState, useEffect, useRef } from "react";
import { DataNodeConfig, timelineConfig } from "src/config/timelineConfig";
import * as d3 from "d3";
import { useNavigate } from "react-router-dom";
import "./TimelineSelector.css"; // Asegúrate de tener estilos relacionados
import { useResizeListener } from "src/hooks/useResizeListener";

const MobileResponsiveConfig = {
  padding: 15,
  fontSize: 14,
  labelMaxWidth: 140,
  baseRadius: 35,
};
const TabletResponsiveConfig = {
  padding: 20,
  fontSize: 14,
  labelMaxWidth: 150,
  baseRadius: 38,
};
const DesktopResponsiveConfig = {
  padding: 30,
  fontSize: 16,
  labelMaxWidth: 160,
  baseRadius: 40,
};

const TimelinePage: React.FC = () => {
  const [currentNodes, setCurrentNodes] =
    useState<DataNodeConfig[]>(timelineConfig);
  const [history, setHistory] = useState<DataNodeConfig[][]>([]);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isAnimatingRef = useRef<boolean>(false); // Para evitar animaciones superpuestas
  const navigate = useNavigate();

  const getContainerSize = () => {
    return {
      width: svgRef.current?.parentElement?.clientWidth || window.innerWidth,
      height: svgRef.current?.parentElement?.clientHeight || window.innerHeight,
    };
  };

  const [responsiveConfig, setResponsiveConfig] = useState(
    DesktopResponsiveConfig
  );

  useResizeListener(() => {
    const width = getContainerSize().width;
    if (width < 500) {
      setResponsiveConfig(MobileResponsiveConfig);
    } else if (width < 1000) {
      setResponsiveConfig(TabletResponsiveConfig);
    } else {
      setResponsiveConfig(DesktopResponsiveConfig);
    }
  });

  useEffect(() => {
    if (!svgRef.current) return;

    const { width, height } = getContainerSize();
    const { padding, fontSize, labelMaxWidth, baseRadius } = responsiveConfig;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    svg.selectAll("*").remove();

    // Calcular el radio y el tema de cada burbuja, heredar tema si es necesario
    const nodes: DataNodeConfig[] = [...currentNodes].map((d) => {
      const parentTheme = d.theme || d.children?.[0]?.theme;
      return {
        ...d,
        radius: Math.max(
          getMaxLineWidth(d.label, fontSize, "IBM Plex Mono", labelMaxWidth) /
            2 +
            padding,
          baseRadius
        ),
        x: width / 2 + (Math.random() - 0.5) * 20,
        y: height / 2 + (Math.random() - 0.5) * 20,
        theme: d.theme || parentTheme, // Asignar o heredar tema
      };
    });

    if (history.length > 0 && currentNodes.length > 0) {
      const localCurrentNodes = [...currentNodes];
      const parentId = localCurrentNodes[0].id.split(".")[0];

      const parent = history[history.length - 1].find(
        (node) => node.id === parentId
      );

      const label = parent ? parent.label : "Volver";
      nodes.push({
        id: "back",
        label: label,
        radius: Math.max(
          getMaxLineWidth(label, fontSize, "IBM Plex Mono", labelMaxWidth) / 2 +
            padding,
          baseRadius
        ),
        x: width / 2,
        y: height / 2,
        theme: {
          backgroundColor: "black",
          auxiliaryColor: "black",
          textColor: "white",
        },
      });
    }

    const simulation = d3
      .forceSimulation<DataNodeConfig>(nodes)
      .force("charge", d3.forceManyBody().strength(-50))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force(
        "collision",
        d3
          .forceCollide<DataNodeConfig>()
          .radius((d) => (d.radius || 0) + 3)
          .strength(1)
      )
      .force("x", d3.forceX(width / 2).strength(0.05))
      .force("y", d3.forceY(height / 2).strength(0.05))
      .velocityDecay(0.2)
      .alpha(0.9)
      .alphaDecay(0.02)
      .on("tick", ticked);
    // .on("end", () => animateEntrance());

    const timeout = setTimeout(() => {
      // setReady(true);
      animateEntrance();
    }, 100);

    const drag = d3
      .drag<SVGGElement, DataNodeConfig>()
      .on("start", function (event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      })
      .on("drag", function (event, d) {
        d.fx = event.x;
        d.fy = event.y;
      })
      .on("end", function (event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      });

    const nodeGroups = svg
      .selectAll<SVGGElement, DataNodeConfig>("g.node")
      .data(nodes, (d) => d.id)
      .enter()
      .append("g")
      .attr("class", (d) => (d.id === "back" ? "node back-button" : "node"))
      .call(drag as any)
      .on("click", handleClick);

    nodeGroups
      .append("circle")
      .attr("r", 0)
      .attr("fill", (d) => d.theme?.backgroundColor || "white")
      .style("--hover-fill-color", (d) => d.theme?.auxiliaryColor || "inherit")
      .style("opacity", 0);

    nodeGroups
      .append("text")
      .attr("class", "label-circle")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("pointer-events", "none")
      .style("font-size", `${fontSize}px`)
      .style("fill", (d) => d.theme?.textColor || "black")
      .style("opacity", 0)
      .each(function (d) {
        wrapText(
          d3.select(this),
          d.label,
          getMaxLineWidth(d.label, fontSize, "IBM Plex Mono", labelMaxWidth),
          fontSize,
          "IBM Plex Mono",
          d.theme?.textColor || "black"
        );
      });

    nodeGroups.each(function (d) {
      if (d.id === "back") {
        d.fx = width / 2;
        d.fy = height / 2;
        setTimeout(() => {
          d.fx = null;
          d.fy = null;
        }, 10);
      }
    });

    function ticked() {
      svg
        .selectAll<SVGGElement, DataNodeConfig>("g.node")
        .attr("transform", (d) => `translate(${d.x}, ${d.y})`);
    }

    function animateExit(callback: () => void) {
      if (isAnimatingRef.current) return;
      isAnimatingRef.current = true;

      const textTransitionDuration = 200;
      const circleTransitionDuration = 500;
      const circleDelayStep = 100;

      const nodeGroups = d3.select(svgRef.current).selectAll("g.node");

      nodeGroups
        .select("text")
        .transition()
        .duration(textTransitionDuration)
        .style("opacity", 0)
        .remove();

      nodeGroups
        .select("circle")
        .transition()
        .duration(circleTransitionDuration)
        .delay((d, i) => i * circleDelayStep)
        .attrTween("r", function (d) {
          const data = d as DataNodeConfig;
          if (!data || !data.radius) return () => "0";
          const interpolate = d3.interpolateNumber(data.radius || 0, 0);
          return (t) => interpolate(t).toString();
        })
        .styleTween("opacity", () => {
          const interpolate = d3.interpolateNumber(1, 0);
          return (t: number) => interpolate(t).toString();
        })
        .remove();

      const totalCircleDelay = nodeGroups.size() * circleDelayStep;
      const totalTransitionTime =
        textTransitionDuration + circleTransitionDuration + totalCircleDelay;

      setTimeout(() => {
        isAnimatingRef.current = false;
        callback();
      }, totalTransitionTime);
    }

    function handleClick(event: any, d: DataNodeConfig) {
      if (isAnimatingRef.current) return;
      event.stopPropagation();

      animateExit(() => {
        if (d.id === "back") {
          const previousNodes = history[history.length - 1];
          setHistory(history.slice(0, -1));
          setCurrentNodes(previousNodes);
        } else if (!d.children && (!d.endpoint || d.endpoint === "")) {
          return;
        } else if (d.children && d.children.length > 0) {
          setHistory([...history, currentNodes]);
          setCurrentNodes(d.children);
        } else if (d.endpoint) {
          const localCurrentNodes = [...currentNodes];
          const parentId = localCurrentNodes[0].id.split(".")[0];

          const parent = history[history.length - 1].find(
            (node) => node.id === parentId
          );
          if (parent) {
            // Redirigir al nuevo endpoint
            const variableId = parent.id;
            const graphId = d.id;

            // Redirige a la nueva ruta utilizando los IDs
            navigate(`/analyst/timeline/${variableId}/${graphId}`);
          }
        }
      });
    }

    function handleBackgroundClick() {
      if (isAnimatingRef.current) return;

      animateExit(() => {
        if (history.length > 0) {
          const previousNodes = history[history.length - 1];
          setHistory(history.slice(0, -1));
          setCurrentNodes(previousNodes);
        } else {
          setCurrentNodes(timelineConfig);
          navigate("/analyst");
        }
      });
    }

    svg.on("click", handleBackgroundClick);

    simulation.alpha(0.3).restart();

    return () => {
      simulation.stop();
      clearTimeout(timeout);
      svg.on("click", null); // Limpiar el manejador de eventos click
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNodes, history, responsiveConfig]);

  const animateEntrance = () => {
    const svg = d3.select(svgRef.current);
    const nodeGroups = svg.selectAll<SVGGElement, DataNodeConfig>("g.node");

    nodeGroups
      .select("circle")
      .transition()
      .delay((d, i) => i * 100)
      .duration(500)
      .ease(d3.easeCubicOut)
      .attrTween("r", (d) => {
        const interpolate = d3.interpolateNumber(0, d.radius || 0);
        return (t) => interpolate(t).toString();
      })
      .style("opacity", 1)
      .on("end", function () {
        const parent = (this as Element).parentNode as Element;
        if (parent) {
          d3.select(parent)
            .select("text")
            .transition()
            .delay(20)
            .duration(200)
            .style("opacity", 1);
        }
      });
  };

  function getTextWidth(
    text: string,
    fontSize: number,
    fontFamily: string
  ): number {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    context.font = `${fontSize}px ${fontFamily}`;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  function getMaxLineWidth(
    text: string,
    fontSize: number,
    fontFamily: string,
    maxWidth: number
  ): number {
    const words = text.split(" ");
    let line = "";
    let maxLineWidth = 0;

    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + " ";
      const testWidth = getTextWidth(testLine, fontSize, fontFamily);
      if (testWidth > maxWidth && line.length > 0) {
        maxLineWidth = Math.max(
          maxLineWidth,
          getTextWidth(line, fontSize, fontFamily)
        );
        line = words[i] + " ";
      } else {
        line = testLine;
      }
    }
    maxLineWidth = Math.max(
      maxLineWidth,
      getTextWidth(line, fontSize, fontFamily)
    );
    return maxLineWidth;
  }

  function wrapText(
    textElement: d3.Selection<SVGTextElement, unknown, null, undefined>,
    text: string,
    maxWidth: number,
    fontSize: number,
    fontFamily: string,
    fillColor: string
  ) {
    const words = text.split(/\s+/);
    const lines: string[] = [];
    let line = "";

    words.forEach((word) => {
      const testLine = line + word + " ";
      const testWidth = getTextWidth(testLine, fontSize, fontFamily);
      if (testWidth > maxWidth && line.length > 0) {
        lines.push(line.trim());
        line = word + " ";
      } else {
        line = testLine;
      }
    });
    lines.push(line.trim());

    const numLines = lines.length;
    const lineHeightPx = fontSize * 1.2;
    const initialDy = -((numLines - 1) * lineHeightPx) / 2;

    lines.forEach((line, i) => {
      textElement
        .append("tspan")
        .text(line)
        .attr("x", 0)
        .attr("dy", i === 0 ? `${initialDy}px` : `${lineHeightPx}px`)
        .style("fill", fillColor);
    });
  }

  return (
    <div
      className="absolute inset-0 z-10 w-screen h-screen overflow-hidden bg-black/30 text-white"
      ref={containerRef}
    >
      <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>
    </div>
  );
};

export default TimelinePage;
