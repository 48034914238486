export interface RegionConfig {
  shortName: string;
  longName: string;
  order: number;
  color: string; // Color del texto de la región
}
export const regionsConfig: Record<number, RegionConfig> = {
  // Norte de Chile - Colores cálidos (desierto)
  15: {
    shortName: "Arica y Parinacota",
    longName: "Región de Arica y Parinacota",
    order: 1,
    color: "#D84315", // Naranja quemado (desierto)
  },
  1: {
    shortName: "Tarapacá",
    longName: "Región de Tarapacá",
    order: 2,
    color: "#D84315", // Naranja quemado (desierto)
  },
  2: {
    shortName: "Antofagasta",
    longName: "Región de Antofagasta",
    order: 3,
    color: "#B22222", // Rojo fuego (desierto)
  },
  3: {
    shortName: "Atacama",
    longName: "Región de Atacama",
    order: 4,
    color: "#B22222", // Rojo fuego (desierto)
  },
  4: {
    shortName: "Coquimbo",
    longName: "Región de Coquimbo",
    order: 5,
    color: "#C71585", // Rosa oscuro (transición)
  },

  // Centro de Chile - Grises (ciudad/concreto)
  5: {
    shortName: "Valparaíso",
    longName: "Región de Valparaíso",
    order: 6,
    color: "#6D6D6D", // Gris claro
  },
  13: {
    shortName: "Metropolitana",
    longName: "Región Metropolitana de Santiago",
    order: 7,
    color: "#4A4A4A", // Gris oscuro (concreto)
  },

  // Sur de Chile - Verdes (bosques)
  6: {
    shortName: "O'Higgins",
    longName: "Región del Libertador General Bernardo O'Higgins",
    order: 8,
    color: "#3E865A", // Verde bosque
  },
  7: {
    shortName: "Maule",
    longName: "Región del Maule",
    order: 9,
    color: "#3E865A", // Verde bosque
  },
  16: {
    shortName: "Ñuble",
    longName: "Región de Ñuble",
    order: 10,
    color: "#3E865A", // Verde bosque
  },
  8: {
    shortName: "Biobío",
    longName: "Región del Biobío",
    order: 11,
    color: "#2B6CB0", // Azul oscuro (transición a sur)
  },
  9: {
    shortName: "Araucanía",
    longName: "Región de La Araucanía",
    order: 12,
    color: "#2B6CB0", // Azul oscuro (transición a sur)
  },
  14: {
    shortName: "Los Ríos",
    longName: "Región de Los Ríos",
    order: 13,
    color: "#2B6CB0", // Azul oscuro (ríos y agua)
  },

  // Extremo sur - Azules (Antártica)
  10: {
    shortName: "Los Lagos",
    longName: "Región de Los Lagos",
    order: 14,
    color: "#1E90FF", // Azul profundo (lagos)
  },
  11: {
    shortName: "Aysén",
    longName: "Región de Aysén del General Carlos Ibáñez del Campo",
    order: 15,
    color: "#1E90FF", // Azul profundo (región fría)
  },
  12: {
    shortName: "Magallanes",
    longName: "Región de Magallanes y de la Antártica Chilena",
    order: 16,
    color: "#1E90FF", // Azul profundo (Antártica)
  },
};
