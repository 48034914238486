import React from "react";
import ReactSwitch from "react-switch";
import { AvailableFilter } from "src/types/graph";

interface MetricToggleProps {
  filter: AvailableFilter; // Recibe el filtro completo
  onFilterChange: (filterKey: string, selected: string | number | null) => void; // Callback similar a otros filtros
}

const MetricToggle: React.FC<MetricToggleProps> = ({
  filter,
  onFilterChange,
}) => {
  // Determinar el estado del toggle en base a la selección actual del filtro
  const isToggled = filter.selected === filter.values[1].key;

  // Manejar el cambio del toggle, seleccionando entre los dos valores posibles del filtro
  const handleToggleChange = () => {
    const newValue = isToggled ? filter.values[0].key : filter.values[1].key;
    onFilterChange(filter.key, newValue); // Invocar el callback de cambio
  };

  return (
    <div
      className="flex justify-start items-end space-x-2 relative"
      data-tooltip-id="tooltip"
      data-tooltip-content={
        "Variación Interanual" // Texto del tooltip
      }
    >
      {/* El componente ReactSwitch con el gradiente y los bordes */}
      <ReactSwitch
        checked={isToggled}
        onChange={handleToggleChange}
        handleDiameter={32} // Tamaño del círculo
        offHandleColor="#fff" // Círculo blanco cuando está desactivado
        onHandleColor="#fff" // Círculo blanco cuando está activado
        uncheckedIcon={false} // Sin icono cuando está desactivado
        checkedIcon={false} // Sin icono cuando está activado
        height={32} // Altura del switch
        width={60} // Ancho del switch
        activeBoxShadow="0px 0px 1px 3px rgba(0, 0, 0, 0.2)"
        className="annual-variation-toggle" // Borde y gradiente aplicados con Tailwind
      />
      {/* Etiqueta a la derecha del switch */}
      {/* <span className="m-0 text-xs font-medium absolute -top-1 text-center left-1/2 transform -translate-x-1/2 text-gray-500 -translate-y-full">
        Variación Interanual
      </span> */}
      {/* {isToggled ? filter.values[1].label : filter.values[0].label} */}
      {/* <span className="text-[0.7rem] font-normal tracking-tight">
        Variación Interanual
      </span> */}
    </div>
  );
};

export default MetricToggle;
