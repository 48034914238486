// import './SvgAnimation.css';
import PropTypes from "prop-types";

const Loader = ({
  color = "#FFFFFF",
  backgroundColor = "transparent",
  size = "3rem",
}) => {
  return (
    <div
      className="absolute w-full h-full top-0 left-0 flex items-center justify-center z-10 pointer-events-none inset-0"
      style={{ backgroundColor }}
    >
      <div style={{ width: size, height: size }}>
        <svg
          id="rdx-loader"
          data-name="rdx-loader"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1080 1080"
        >
          <defs></defs>
          <line
            className="rdx-loader-line line-1"
            x1="588"
            y1="214"
            x2="309.9"
            y2="868"
            style={{ stroke: color }}
          />
          <line
            className="rdx-loader-line line-2"
            x1="336"
            y1="473"
            x2="940.5"
            y2="868"
            style={{ stroke: color }}
          />
          <line
            className="rdx-loader-line line-3"
            x1="121"
            y1="868"
            x2="940.5"
            y2="868"
            style={{ stroke: color }}
          />
          <line
            className="rdx-loader-line line-4"
            x1="940.5"
            y1="868"
            x2="662.41"
            y2="214"
            style={{ stroke: color }}
          />
          <line
            className="rdx-loader-line line-5"
            x1="121"
            y1="868"
            x2="336"
            y2="473"
            style={{ stroke: color }}
          />
          <line
            className="rdx-loader-line line-6"
            x1="588"
            y1="214"
            x2="662.41"
            y2="214"
            style={{ stroke: color }}
          />
        </svg>
      </div>
    </div>
  );
};
Loader.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.string,
};

export default Loader;
