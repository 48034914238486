// admin/shared/AlertModal.tsx
import React from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineWarning,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Transition } from "@headlessui/react";

interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: "success" | "warning" | "error";
  message: string;
  note?: string; // Nota opcional
}

const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  onClose,
  type,
  message,
  note,
}) => {
  // Configurar ícono y colores en función del tipo de mensaje
  let icon, colorClass, title;
  switch (type) {
    case "success":
      icon = <AiOutlineCheckCircle className="text-green-500" size={24} />;
      colorClass = "text-green-600";
      title = "Éxito";
      break;
    case "warning":
      icon = <AiOutlineWarning className="text-yellow-500" size={24} />;
      colorClass = "text-yellow-600";
      title = "Advertencia";
      break;
    case "error":
      icon = <AiOutlineExclamationCircle className="text-red-500" size={24} />;
      colorClass = "text-red-600";
      title = "Error";
      break;
    default:
      icon = null;
      colorClass = "text-black";
      title = "";
  }

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="p-6 rounded-lg bg-white shadow-lg max-w-md w-full font-sans">
          {/* Encabezado con el título e ícono */}
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-2">
              {icon}
              <h2 className={`text-xl font-semibold ${colorClass}`}>{title}</h2>
            </div>
            {/* Botón de cierre */}
            <button
              className="hidden text-gray-600 hover:text-gray-800"
              onClick={onClose}
            >
              <IoMdClose size={24} />
            </button>
          </div>
          {/* Mensaje principal */}
          <p className="text-gray-700">{message}</p>
          {/* Nota opcional */}
          {note && <p className="text-sm text-gray-500 mt-2">{note}</p>}
          {/* Botón de cierre */}
          <div className="mt-4 flex justify-center">
            <button
              className={`py-2 px-4 rounded ${colorClass} bg-opacity-90 hover:bg-opacity-100 transition`}
              onClick={onClose}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default AlertModal;
