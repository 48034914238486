import { useWelcomeGuideContext } from "src/context/WelcomeGuideContext";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const HelpWidget: React.FC = () => {
  const { hasGuide, currentSlides, openWelcomeGuide } =
    useWelcomeGuideContext();

  if (!hasGuide) return null; // No mostramos nada si no hay guía disponible

  return (
    <div className="fixed  top-4 right-4 z-40 text-white">
      <button onClick={() => openWelcomeGuide(currentSlides)}>
        <AiOutlineQuestionCircle size={28} />
      </button>
    </div>
  );
};

export default HelpWidget;
