import React, { useRef } from "react";
import useScrollMask from "src/hooks/useScrollMask"; // Activa el scroll con máscara

interface FullscreenLayoutProps {
  header: string | React.ReactNode;
  children: React.ReactNode;
  backgroundColor?: string; // Permitir personalización del color de fondo
  textColor?: string; // Permitir personalización del color del texto
}

const FullscreenLayout: React.FC<FullscreenLayoutProps> = ({
  header,
  children,
  backgroundColor = "white", // Fondo por defecto blanco
  textColor = "black", // Texto por defecto negro
}) => {
  const scrollableParentContainer = useRef<HTMLDivElement>(null);

  // Activar el efecto de scroll "bonito" con máscara
  useScrollMask(scrollableParentContainer);

  return (
    <div
      className="fixed inset-0 z-10 w-screen h-dvh flex flex-col"
      style={{
        backgroundColor: backgroundColor, // Aplicar el color de fondo condicionalmente
        color: textColor, // Aplicar el color de texto condicionalmente
        transition: "background-color 700ms, color 700ms", // Transición de color
      }}
    >
      {/* Header fijo en la parte superior */}
      <div className="p-5 sm:p-7">
        <div className="max-w-screen-sm mx-auto">
          {typeof header === "string" ? (
            <h1 className="text-lg sm:text-xl font-regular text-center">
              {header}
            </h1>
          ) : (
            header
          )}
        </div>
      </div>

      {/* Contenido del fullscreen que toma todo el espacio restante y permite scroll */}
      <div className="flex-1 p-5 pb-20 sm:pb-28 sm:p-7">
        {/* Cuerpo scrollable centrado */}
        <div
          ref={scrollableParentContainer}
          className="relative w-full h-full max-w-screen-lg mx-auto overflow-y-auto no-scrollbar"
        >
          {/* Cuerpo scrollable con padding adicional para la barra de navegación */}
          <div className="absolute w-full min-h-full flex flex-col justify-start p-0">
            <div className="w-full">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullscreenLayout;
