// MapContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  ReactNode,
} from "react";
import { MapRef } from "react-map-gl";

interface Viewport {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
}

interface MapContextType {
  mapRef: React.MutableRefObject<MapRef | null>;
  viewport: Viewport;
  setViewport: React.Dispatch<React.SetStateAction<Viewport>>;
  flyTo: (coords: [number, number], zoom?: number) => void;
  isRegionInteractionEnabled: boolean;
  setRegionInteractionEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}
interface MapProviderProps {
  children: ReactNode;
}

const MapContext = createContext<MapContextType | undefined>(undefined);

export const MapProvider: React.FC<MapProviderProps> = ({ children }) => {
  const mapRef = useRef<MapRef>(null);
  const [viewport, setViewport] = useState<Viewport>({
    latitude: -40,
    longitude: -70,
    zoom: 4,
    bearing: 90,
    pitch: 0,
  });
  const [isRegionInteractionEnabled, setRegionInteractionEnabled] =
    useState(true);
  const flyTo = useCallback((coords: [number, number], zoom: number = 14) => {
    if (!mapRef.current) {
      console.warn("Attempted to call flyTo before the map was initialized");
      return;
    }
    mapRef.current.flyTo({
      center: coords,
      zoom: zoom,
      speed: 1,
      curve: 1,
    });
  }, []);

  return (
    <MapContext.Provider
      value={{
        mapRef,
        viewport,
        setViewport,
        flyTo,
        isRegionInteractionEnabled,
        setRegionInteractionEnabled,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMap = (): MapContextType => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMap must be used within a MapProvider");
  }
  return context;
};
