import html2canvas from "html2canvas-pro";
import jsPDF from "jspdf";
import gsap from "gsap";

// Detectar si es un dispositivo móvil (Android o iOS)
const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

export const takeScreenshotAndDownload = async () => {
    try {
        const elementsToHide = document.querySelectorAll(".hidden-for-screenshot");

        // Guardar los estilos originales
        const originalStyles: {
            element: Element;
            opacity: number;
            visibility: string;
            pointerEvents: string;
        }[] = [];

        elementsToHide.forEach((element) => {
            const computedStyle = window.getComputedStyle(element as HTMLElement);
            originalStyles.push({
                element,
                opacity: parseFloat(computedStyle.opacity),
                visibility: computedStyle.visibility,
                pointerEvents: computedStyle.pointerEvents,
            });
        });

        // Ocultar elementos con animación suave
        await gsap.to(elementsToHide, {
            opacity: 0,
            duration: 0.5,
            ease: "power2.out",
        });

        const body = document.body;
        const isLandscape = body.clientWidth > body.clientHeight;
        const bodyWidth = isLandscape ? 1600 : 500;
        const bodyHeight = isLandscape ? 1000 : 1000;

        // Capturar la pantalla como canvas
        const canvas = await html2canvas(body.parentElement || body, {
            scale: 3,
            logging: false,
            useCORS: true,
            foreignObjectRendering: false,
            windowHeight: bodyHeight,
            windowWidth: bodyWidth,
            width: bodyWidth,
            height: bodyHeight,
            // removeContainer: false,
            onclone(document) {
                const elementsToHide = document.querySelectorAll(".hidden-for-screenshot");
                elementsToHide.forEach((element) => gsap.set(element, { display: "none" }));
                const elementsToRemove = document.querySelectorAll(".remove-for-screenshot");
                elementsToRemove.forEach((element) => gsap.set(element, { display: "none" }));
                const elementsToShow = document.querySelectorAll(".show-for-screenshot");
                elementsToShow.forEach((element) => gsap.set(element, { display: "block", opacity: 1 }));
                const inputs = document.querySelectorAll("input");
                inputs.forEach((input) => input.remove());
            },
        });


        // Restaurar los estilos inmediatamente después de la captura
        originalStyles.forEach((style) => {
            const el = style.element as HTMLElement;
            el.style.visibility = style.visibility;
            el.style.pointerEvents = style.pointerEvents;
            gsap.to(el, {
                opacity: style.opacity,
                duration: 0.5,
                ease: "power2.in",
            });
        });

        const imgData = canvas.toDataURL("image/jpeg", 1.0);

        if (isMobile) {
            const blob = await (await fetch(imgData)).blob();
            const file = new File([blob], `rdx-${Date.now()}.jpg`, { type: "image/jpeg" });
            try {
                // Intentar usar el API de compartir en dispositivos móviles
                if (navigator.share) {
                    await navigator.share({
                        files: [file],
                        title: "RDX Screenshot",
                        text: "Mira esta captura desde RDX.",
                    });
                }
                else {
                    // Fallback: Descargar la imagen si el compartir falla o no está disponible
                    const link = document.createElement("a");
                    link.href = imgData;
                    link.download = `rdx-${Date.now()}.jpg`;
                    link.click();
                }
            } catch (error) {
                console.warn("Sharing failed, falling back to download:", error);
            }

        } else {
            // **Escritorio**: Generar y descargar PDF
            const pdf = new jsPDF({
                orientation: isLandscape ? "landscape" : "portrait",
                unit: "px",
                format: [bodyWidth, bodyHeight],
            });
            pdf.addImage(imgData, "JPEG", 0, 0, bodyWidth, bodyHeight);
            pdf.save(`rdx-${Date.now()}.pdf`);
        }
    } catch (error) {
        console.error("Error taking screenshot:", error);
    }
};