import React from "react";
import {
  Resource,
  ResourceAccessLabels,
  ResourceAccessType,
} from "../../../types/Resource";
import {
  AiOutlineCloudUpload,
  AiOutlineLink,
  AiOutlineSearch,
  AiOutlineClockCircle,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai";

import formatDate from "src/admin/helpers/formatDate";

interface ResourceItemProps {
  resource: Resource;
  onView: (resource: Resource) => void;
}

// Función para obtener el icono y la etiqueta según el tipo de acceso
const getAccessTypeInfo = (accessType: ResourceAccessType) => {
  const typeInfo = ResourceAccessLabels[accessType];

  if (!typeInfo) {
    return { icon: null, label: "Desconocido" };
  }

  let icon;
  switch (accessType) {
    case "manual-input":
      icon = <AiOutlineCloudUpload size={16} className="text-blue-500" />;
      break;
    case "direct-link":
      icon = <AiOutlineLink size={16} className="text-green-500" />;
      break;
    case "site-link-search":
    case "site-row-search":
      icon = <AiOutlineSearch size={16} className="text-yellow-500" />;
      break;
    default:
      icon = null;
  }

  return { icon, label: typeInfo.labelShort };
};

// Función para obtener el estilo del estado
const getStatusInfo = (status: "success" | "error" | "warning" | null) => {
  switch (status) {
    case "success":
      return {
        text: "Actualizado",
        className: "bg-[#33FF00] text-black",
        icon: <AiOutlineCheckCircle size={15} />,
      };
    case "error":
      return {
        text: "Error",
        className: "bg-[#FF6C1A] text-black",
        icon: <AiOutlineExclamationCircle size={15} />,
      };
    case "warning":
      return {
        text: "Advertencia",
        className: "bg-[#FFFE00] text-black",
        icon: <AiOutlineExclamationCircle size={15} />,
      };
    default:
      return {
        text: "Sin registros",
        className: "bg-transparent text-gray-500",
        icon: <AiOutlineClockCircle size={15} />,
      };
  }
};

const ResourceItem: React.FC<ResourceItemProps> = ({ resource, onView }) => {
  const { icon, label } = getAccessTypeInfo(resource.access_type);
  const {
    text: statusText,
    className: statusClass,
    icon: statusIcon,
  } = getStatusInfo(resource.status);

  return (
    <div
      className="min-h-48 resource-item p-5 pb-4 border rounded-xl bg-white shadow-lg transition-all cursor-pointer hover:shadow-xl hover:scale-[1.01] flex flex-col justify-between"
      onClick={() => onView(resource)}
    >
      <div>
        {/* Encabezado con el nombre del recurso */}
        <div className="flex items-center space-x-3 mb-3">
          <h3 className="font-medium text-lg">{resource.name}</h3>
        </div>

        {/* Tipo de acceso con ícono y etiqueta */}
        <div className="flex items-center text-sm text-gray-500 mb-4 space-x-2">
          {icon}
          <span>{label}</span>
        </div>
      </div>

      {/* Detalles de la fecha de actualización y el estado */}
      <div className="border-t pt-2 flex items-center justify-between">
        <div className="flex items-center space-x-1">
          <span className="text-xs text-gray-400">
            {resource.last_updated
              ? formatDate(resource.last_updated)
              : "Aún no hay registros"}
          </span>
        </div>
        <div
          className={`flex items-center space-x-1 px-3 py-1.5 rounded-full ${statusClass}`}
        >
          {statusIcon}
          <span className="text-xs font-semibold">{statusText}</span>
        </div>
      </div>
    </div>
  );
};

export default ResourceItem;
