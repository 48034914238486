import React, { createContext, useContext, useState, ReactNode } from "react";
import WelcomeGuideModal from "src/components/WelcomeGuideModal";
import { Slide } from "src/types/modal.d";

interface WelcomeGuideContextType {
  openWelcomeGuide: (slides: Slide[], viewKey?: string) => void;
  closeWelcomeGuide: () => void;
  setHasGuide: (value: boolean) => void;
  setCurrentSlides: (slides: Slide[]) => void;
  isOpen: boolean;
  hasGuide: boolean;
  currentSlides: Slide[];
}

// interface Slide {
//   title: string;
//   subtitle: string;
//   image: string;
// }

const WelcomeGuideContext = createContext<WelcomeGuideContextType | undefined>(
  undefined
);

export const useWelcomeGuideContext = () => {
  const context = useContext(WelcomeGuideContext);
  if (!context) {
    throw new Error(
      "useWelcomeGuideContext must be used within a WelcomeGuideProvider"
    );
  }
  return context;
};

export const WelcomeGuideProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasGuide, setHasGuide] = useState(false);
  const [slides, setSlides] = useState<Slide[]>([]);
  const [currentSlides, setCurrentSlides] = useState<Slide[]>([]);

  const openWelcomeGuide = (slides: Slide[], viewKey?: string) => {
    if (viewKey && hasSeenWelcomeGuide(viewKey)) return;

    setSlides(slides);
    setIsOpen(true);

    if (viewKey) markWelcomeGuideAsSeen(viewKey);
  };

  const closeWelcomeGuide = () => setIsOpen(false);

  return (
    <WelcomeGuideContext.Provider
      value={{
        openWelcomeGuide,
        closeWelcomeGuide,
        setHasGuide,
        setCurrentSlides,
        isOpen,
        hasGuide,
        currentSlides,
      }}
    >
      {children}
      <WelcomeGuideModal
        slides={slides}
        isOpen={isOpen}
        onClose={closeWelcomeGuide}
      />
    </WelcomeGuideContext.Provider>
  );
};

// Utilidades de almacenamiento
const hasSeenWelcomeGuide = (viewKey: string): boolean =>
  localStorage.getItem(`welcome_guide_${viewKey}`) === "true";

const markWelcomeGuideAsSeen = (viewKey: string) =>
  localStorage.setItem(`welcome_guide_${viewKey}`, "true");
