import { useEffect, useRef } from "react";

// Función para formatear los números al estilo español
const formatNumberToSpanish = (value: string): string => {
  const parsedValue = parseFloat(value.replaceAll(",", "").replaceAll(".", ""));
  if (isNaN(parsedValue)) return value;

  // Formatear usando el formato español
  return new Intl.NumberFormat("es-ES", {
    maximumFractionDigits: 0,
  }).format(parsedValue);
};

// Hook para aplicar el formato en elementos específicos
const useNumberFormattingFix = (parentRef: React.RefObject<HTMLElement>) => {
  const frameRef = useRef<number | null>(null);

  const fixFormatting = (parent: HTMLElement | null) => {
    if (!parent) return;

    const elements = parent.querySelectorAll(".valueLabel, .tick text");

    elements.forEach((el) => {
      const originalText = el.textContent || "";
      if (originalText.length > 4) {
        const formattedText = formatNumberToSpanish(originalText);
        if (originalText !== formattedText) {
          el.textContent = formattedText;
        }
      }
    });
  };

  useEffect(() => {
    if (!parentRef.current) return;

    const loop = () => {
      fixFormatting(parentRef.current);
      frameRef.current = requestAnimationFrame(loop);
    };

    frameRef.current = requestAnimationFrame(loop);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
        console.log("Se canceló el " + frameRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef, parentRef.current]);
};

export default useNumberFormattingFix;
