import React, { useEffect } from "react";
import { VscGraphLine, VscTable } from "react-icons/vsc";
import { GraphViewMode } from "src/types/graph";
import { LuBarChart4 } from "react-icons/lu";

interface ViewModeSwitchProps {
  viewMode: GraphViewMode;
  onChange: (mode: GraphViewMode) => void;
  availableCharts: string[]; // Aquí asumo que viene como 'string[]', por eso necesitamos la verificación
  defaultChart: GraphViewMode; // Se incluye el chart por defecto
}

// Type guard para verificar que un valor es un GraphViewMode
const isGraphViewMode = (value: string): value is GraphViewMode => {
  return ["bar", "line", "table"].includes(value);
};

const ViewModeSwitch: React.FC<ViewModeSwitchProps> = ({
  viewMode,
  onChange,
  availableCharts,
  defaultChart,
}) => {
  // Solo los modos que están disponibles según graphConfig
  const availableModes = [
    {
      mode: "bar" as GraphViewMode,
      icon: <LuBarChart4 className="text-lg" />,
    },
    {
      mode: "line" as GraphViewMode,
      icon: <VscGraphLine className="text-lg" />,
    },
    { mode: "table" as GraphViewMode, icon: <VscTable className="text-lg" /> },
  ].filter(
    (mode) => isGraphViewMode(mode.mode) && availableCharts.includes(mode.mode)
  );

  // Efecto para asegurar que el modo actual es válido
  useEffect(() => {
    // Si el modo actual no está disponible, usar el gráfico por defecto
    if (!availableModes.some((mode) => mode.mode === viewMode)) {
      onChange(defaultChart);
    }
  }, [viewMode, availableModes, defaultChart, onChange]);

  // Encontramos el índice del modo actual en los gráficos disponibles
  const currentIndex = availableModes.findIndex(
    (mode) => mode.mode === viewMode
  );

  // Calculamos el valor de desplazamiento basado en el índice
  const translateValue = currentIndex * 2.225; // Ajuste basado en tamaño y espaciado

  return (
    <div className="relative inline-flex items-center justify-start bg-white rounded-full h-8 px-2">
      {/* Círculo que se mueve */}
      <div
        className="absolute w-7 h-7 bg-black rounded-full shadow-md transition-transform duration-300 ease-in-out"
        style={{ transform: `translateX(${translateValue}rem)` }} // Ajuste de la posición
      ></div>

      {/* Contenedor de los botones */}
      <div className="flex space-x-2">
        {/* Espaciado entre botones */}
        {availableModes.map((mode) => (
          <button
            key={mode.mode}
            onClick={() => onChange(mode.mode)}
            className={`scale-90 z-0 flex items-center justify-center w-7 h-7 text-black transition-all duration-200 ease-in-out ${
              viewMode === mode.mode ? "text-white" : "text-gray-500"
            }`}
          >
            {mode.icon}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ViewModeSwitch;
