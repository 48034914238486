import { useWelcomeGuide } from "src/hooks/useWelcomeGuide";

const WelcomeGuideAnalyticsRoot = () => {
  useWelcomeGuide(
    [
      // {
      //   title: "Bienvenido a la vista analista",
      //   subtitle:
      //     "Al entrar al modo Analista, podrás revisar estadísticas y realizar comparaciones entre regiones seleccionándolas, después simplemente elige qué tipo de información desees saber.",
      //   videoSrc: "/assets/welcome-guide/analyst/analyst-1-desktop.mp4",
      //   mediaType: "video",
      // },
      // {
      //   title: "Líneas de tiempo",
      //   subtitle:
      //     "Para ver líneas de tiempo, presiona el reloj ubicado en la parte inferior del sitio. Elige la información que desees ver y su filtro, podrás cambiar estos datos presionando sobre los textos subrayados.",
      //   videoSrc: "/assets/welcome-guide/analyst/analyst-2-desktop.mp4",
      //   mediaType: "video",
      // },
      // {
      //   title: "Análisis avanzado",
      //   subtitle:
      //     "Para conocer en análisis avanzado, presiona el gráfico con destellos ubicado en la parte inferior del sitio.",
      //   videoSrc: "/assets/welcome-guide/analyst/analyst-3-desktop.mp4",
      //   mediaType: "video",
      // },
      {
        title: "Volver al inicio",
        subtitle:
          "Para volver a la vista inicial, debes presionar el botón Analista, ubicado en la parte inferior izquierda de la pantalla.",
        image: "/assets/welcome-guide/analyst/analyst-4-desktop.png",
        mobileImage: "/assets/welcome-guide/analyst/analyst-4-mobile.png",
      },
    ],
    WelcomeGuideAnalyticsRoot.name
  );

  return null;
};

export default WelcomeGuideAnalyticsRoot;
