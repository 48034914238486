import { useState } from "react";
import axios from "axios";

export type ProcessedResource = {
  file_name: string;
  processed_at: string;
  resource_key: string;
  resource_name: string;
};

const useProcessedResourceApi = () => {
  const [processedResources, setProcessedResources] = useState<
    ProcessedResource[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Helper function to reset error state
  const resetError = () => {
    setError(null);
  };

  const fetchProcessedResources = async () => {
    resetError();
    setIsLoading(true);
    try {
      const response = await axios.get<ProcessedResource[]>(
        "/api/admin/processed-resources"
      );
      setProcessedResources(response.data);
    } catch (err) {
      setError("Error al cargar los recursos procesados");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    processedResources,
    error,
    isLoading,
    fetchProcessedResources,
  };
};

export default useProcessedResourceApi;
