import { useEffect } from "react";
import { useWelcomeGuideContext } from "src/context/WelcomeGuideContext";
import { Slide } from "src/types/modal.d";

// interface Slide {
//   title: string;
//   subtitle: string;
//   image: string;
// }

export const useWelcomeGuide = (
  slides: Slide[],
  viewKey?: string,
  autoOpen: boolean = true
) => {
  const { openWelcomeGuide, setHasGuide, setCurrentSlides, isOpen } =
    useWelcomeGuideContext();

  useEffect(() => {
    if (viewKey) {
      setHasGuide(true);
      setCurrentSlides(slides); // Guardamos las slides actuales
    }
    return () => {
      setHasGuide(false);
      setCurrentSlides([]); // Limpiamos las slides al salir de la vista
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoOpen && viewKey) {
      openWelcomeGuide(slides, viewKey); // Apertura automática si corresponde
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoOpen, slides, viewKey]);

  return {
    openWelcomeGuide: () => openWelcomeGuide(slides), // Apertura manual
    isOpen, // Estado del modal
  };
};
