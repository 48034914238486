import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { MapProvider } from "./context/MapContext"; // Suponiendo que tienes un contexto de mapa
import AnalyticsViewer from "./views/AnalyticsViewer";
import PredictPage from "./views/PredictViewer";
import axios from "axios";

// import { FpsView } from "react-fps";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import ActionMenu from "./views/Overlay/ActionMenu";
import InteractiveMap from "./views/InteractiveMap";
import { GeographicSelectionProvider } from "./context/GeographicSelectionContext";
import VisualizationCategoryMenu from "./views/Overlay/VisualizationCategoryMenu";
import Attribution from "./views/Overlay/Attribution";
import { ReadingModeProvider } from "./context/ReadingModeContext";
import TimelineViewer from "./views/TimelineViewer";
import TimelineSelector from "./views/TimelineSelector";
import Login from "./admin/LoginPage";
import AdminMenu from "./admin/shared/AdminMenu";
// import AttractionsManager from "./views/InteractiveMap/AttractionsManager";
import UserProfileSelector from "./views/UserProfileSelector";
import { WelcomeGuideProvider } from "src/context/WelcomeGuideContext";
import TooltipListener from "./components/TooltipListener";
import TouristView from "./views/Attractions/TouristView";
import WelcomeGuideAnalyticsRoot from "./views/AnalyticsViewer/WelcomeGuideAnalyticsRoot";
import ResourcesPage from "./admin/modules/resources/pages/ResourcesPage";
import ProcessedResourcesPage from "./admin/modules/history/pages/ProcessedResourcesPage";
import { useEffect } from "react";
import SessionTimeoutHandler from "./admin/shared/SessionTimeoutHandler";
// Registrar elementos de ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export enum UserProfile {
  ADMIN = "admin",
  ANALYST = "analyst",
  TOURIST = "tourist",
  UNKNOWN = "unknown",
}

// Componente para proteger la ruta
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const token = localStorage.getItem("accessToken");
  return token ? children : <Navigate to="/login" />;
};

const determineUserProfile = (pathname: string): UserProfile => {
  switch (true) {
    case pathname.includes("/admin") || pathname.includes("/login"):
      return UserProfile.ADMIN;
    case pathname.includes("/analyst"):
      return UserProfile.ANALYST;
    case pathname.includes("/tourist"):
      return UserProfile.TOURIST;
    default:
      return UserProfile.UNKNOWN; // Ruta desconocida
  }
};

function App() {
  // Configuración global de Axios
  axios.defaults.baseURL =
    process.env.REACT_APP_API_URL || "http://localhost:8000";
  // "https://integrations.riolab.com/sst-tourism/"; //|| "http://localhost:4321";

  const navigate = useNavigate();
  const location = useLocation();
  const userProfile = determineUserProfile(location.pathname);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token && location.pathname === "/login") {
      navigate("/admin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, localStorage]);

  if (userProfile === UserProfile.ADMIN) {
    return (
      <>
        <Routes>
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <ResourcesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/notifications"
            element={
              <PrivateRoute>
                <ProcessedResourcesPage />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Attribution />
        {location.pathname !== "/login" && <AdminMenu />}
        <div className="portrait:hidden text-3xl text-black fixed left-4 bottom-14 font-light">
          Administración de datos
        </div>
        <SessionTimeoutHandler />
      </>
    );
  }

  return (
    <WelcomeGuideProvider>
      <MapProvider>
        <ReadingModeProvider>
          <GeographicSelectionProvider>
            <InteractiveMap userProfile={userProfile}>
              {userProfile === UserProfile.TOURIST && (
                <>
                  {/* <AttractionsManager /> */}
                  <TouristView />
                </>
              )}
            </InteractiveMap>

            {userProfile === UserProfile.UNKNOWN && <UserProfileSelector />}

            {userProfile === UserProfile.ANALYST && (
              <>
                <VisualizationCategoryMenu />
                <ActionMenu />
                <Routes>
                  <Route
                    path="analyst/"
                    element={
                      <>
                        <WelcomeGuideAnalyticsRoot />
                      </>
                    }
                  />
                  {/* <Route path="/graph" element={<div>grpah</div>} /> */}
                  <Route path="/analyst/predict" element={<PredictPage />} />
                  <Route
                    path="/analyst/timeline"
                    element={<TimelineSelector />}
                  />
                  <Route
                    path="/analyst/timeline/:variableId/:graphId"
                    element={<TimelineViewer />}
                  />
                  <Route
                    path="/analyst/analytics/:categoryId/:graphId"
                    element={<AnalyticsViewer />}
                  />
                </Routes>
              </>
            )}

            <TooltipListener />
            <Attribution />
          </GeographicSelectionProvider>
        </ReadingModeProvider>
      </MapProvider>
    </WelcomeGuideProvider>
  );
}

export default App;
