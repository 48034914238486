import React, { useRef, useImperativeHandle, useMemo, useEffect } from "react";
import { gsap } from "gsap";
import "tailwindcss/tailwind.css";

interface GridComponentProps {
  showHorizontalLine1?: boolean[];
  showHorizontalLine2?: boolean[];
  showHorizontalLine3?: boolean[];
  showVerticalLine1?: boolean[];
  showVerticalLine2?: boolean[];
  showVerticalLine3?: boolean[];
  target_color?: string;
  initial_color?: string;
  autoAnimate?: boolean;
  defaultInAnimationDuration?: number;
}

export interface GridComponentHandle {
  animateIn: (duration?: number) => void;
  animateOut: (duration?: number) => void;
}

const LINE_WIDTH = "1px";
// const initial_color = '#fff';
// const target_color = '#000';

const GridComponent = React.forwardRef<GridComponentHandle, GridComponentProps>(
  (
    {
      showHorizontalLine1 = [true, true, true, true],
      showHorizontalLine2 = [true, true, true, true],
      showHorizontalLine3 = [true, true, true, true],
      showVerticalLine1 = [true, true, true, true],
      showVerticalLine2 = [true, true, true, true],
      showVerticalLine3 = [true, true, true, true],
      initial_color = "#fff",
      target_color = "#000",
      autoAnimate = false,
      defaultInAnimationDuration = 5,
    },
    ref
  ) => {
    const gridRef = useRef<HTMLDivElement>(null);

    const calculateDelay = (
      lineLength: number,
      duration: number,
      index: number
    ) => {
      return (duration / lineLength) * index;
    };

    const getTransformOrigin = (isHorizontal: boolean, direction: string) => {
      if (isHorizontal) {
        return direction === "ltr" ? "left" : "right";
      } else {
        return direction === "ltr" ? "top" : "bottom";
      }
    };

    const getLineAnimation = (
      lines: HTMLDivElement[],
      duration: number,
      reverse: boolean = false
    ) => {
      const timeline = gsap.timeline();
      lines.forEach((line) => {
        const isHorizontal =
          line.getAttribute("data-orientation") === "horizontal";
        const index = parseFloat(line.getAttribute("data-index")!);
        const direction = line.getAttribute("data-direction")!;

        const delay = calculateDelay(
          lines.length,
          duration,
          direction === "ltr" ? index : 4 - 1 - index
        );
        // lines.length - 1 -
        const transformOrigin = getTransformOrigin(
          isHorizontal,
          reverse ? (direction === "ltr" ? "rtl" : "ltr") : direction
        );

        if (isHorizontal) {
          timeline.fromTo(
            line,
            { scaleX: reverse ? 1 : 0, transformOrigin },
            {
              scaleX: reverse ? 0 : 1,
              duration: duration / lines.length,
              ease: "none",
            },
            delay
          );
        } else {
          timeline.fromTo(
            line,
            { scaleY: reverse ? 1 : 0, transformOrigin },
            {
              scaleY: reverse ? 0 : 1,
              duration: duration / lines.length,
              ease: "none",
            },
            delay
          );
        }
      });
      return timeline;
    };

    useImperativeHandle(ref, () => ({
      animateIn(duration = 1) {
        if (gridRef.current) {
          const lines = Array.from(
            gridRef.current.querySelectorAll<HTMLDivElement>(".line")
          );
          const timeline = getLineAnimation(lines, duration);
          gsap.set(lines, { backgroundColor: initial_color }); // Color inicial blanco
          timeline.to(lines, { backgroundColor: target_color, duration: 1 }); // Cambiar color a negro al final de la animación
        }
      },
      animateOut(duration = 1) {
        if (gridRef.current) {
          const lines = Array.from(
            gridRef.current.querySelectorAll<HTMLDivElement>(".line")
          );
          const timeline = gsap.timeline();
          timeline.to(lines, { backgroundColor: initial_color, duration: 0.1 }); // Cambiar color a blanco al principio
          timeline.add(getLineAnimation(lines, duration - 0.1, true)); // Añadir animación de salida
        }
      },
    }));

    useEffect(() => {
      if (autoAnimate && gridRef.current) {
        const lines = Array.from(
          gridRef.current.querySelectorAll<HTMLDivElement>(".line")
        );
        const timeline = getLineAnimation(lines, defaultInAnimationDuration);
        gsap.set(lines, { backgroundColor: initial_color }); // Color inicial blanco
        timeline.to(lines, { backgroundColor: target_color, duration: 1 });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderLines = useMemo(() => {
      const lines: JSX.Element[] = [];
      const positions = [25, 50, 75]; // Posiciones para las líneas

      const horizontalLines = [
        showHorizontalLine1,
        showHorizontalLine2,
        showHorizontalLine3,
      ];
      const verticalLines = [
        showVerticalLine1,
        showVerticalLine2,
        showVerticalLine3,
      ];

      horizontalLines.forEach((line, i) => {
        const topPosition = `${positions[i]}%`;
        const direction = Math.random() > 0.5 ? "ltr" : "rtl";
        line.forEach((show, j) => {
          const leftPosition = `${j * 25}%`;
          lines.push(
            <div
              key={`h-line-${i}-${j}`}
              className="line absolute"
              data-orientation="horizontal"
              data-line={i}
              data-index={j}
              data-direction={direction}
              style={{
                top: topPosition,
                left: leftPosition,
                width: "25%",
                display: show ? "block" : "none",
                height: `${LINE_WIDTH}`,
                backgroundColor: `${target_color}`,
              }}
            ></div>
          );
        });
      });

      verticalLines.forEach((line, i) => {
        const leftPosition = `${positions[i]}%`;
        const direction = Math.random() > 0.5 ? "ltr" : "rtl";
        line.forEach((show, j) => {
          const topPosition = `${j * 25}%`;
          lines.push(
            <div
              key={`v-line-${i}-${j}`}
              className="line absolute"
              data-orientation="vertical"
              data-line={i}
              data-index={j}
              data-direction={direction}
              style={{
                left: leftPosition,
                top: topPosition,
                height: "25%",
                display: show ? "block" : "none",
                width: `${LINE_WIDTH}`,
                backgroundColor: `${target_color}`,
              }}
            ></div>
          );
        });
      });

      return lines;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        ref={gridRef}
        className="fixed z-20 inset-0 grid grid-cols-4 grid-rows-4 gap-0 pointer-events-none"
      >
        <div className="absolute w-full h-full pointer-events-none">
          {renderLines}
        </div>
      </div>
    );
  }
);

GridComponent.displayName = "GridComponent";

export default GridComponent;
