import { toZonedTime } from "date-fns-tz";

const formatDate = (dateString: string) => {
  // all time is in UTC, check if missing Z
  if (!dateString.includes("Z")) {
    dateString = dateString + "Z";
  }
  const date = new Date(dateString);
  const timeZone = "America/Santiago"; // Zona horaria de Chile
  const zonedDate = toZonedTime(date, timeZone); // Convierte a la zona horaria de Chile

  return new Intl.DateTimeFormat("es-CL", {
    dateStyle: "long",
    timeStyle: "short",
  }).format(zonedDate);
};

export default formatDate;
