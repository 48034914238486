import React from "react";
import { VisualizationSource } from "src/types/visualization";

interface DataSourceProps {
  source?: VisualizationSource | null; // Fuente opcional
  className?: string; // Clase opcional para personalización
}

const DataSource: React.FC<DataSourceProps> = ({ source, className }) => {
  // Si no hay fuente, retorna vacío
  if (!source) return null;

  const { name, url } = source;

  return (
    <div
      className={`border border-black text-justify font-medium p-3  ${
        className || ""
      }`}
    >
      <p className="text-xs">
        <b>Fuente:</b>{" "}
        {url ? (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-thickness-thin underline-offset-small"
          >
            {name}
          </a>
        ) : (
          name
        )}
      </p>
    </div>
  );
};

export default DataSource;
