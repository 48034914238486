// src/admin/types/Resource.ts
export type ResourceAccessType =
  | "manual-input"
  | "direct-link"
  | "site-link-search"
  | "site-row-search";

export interface Resource {
  id: number;
  resource_key: string;
  access_type: ResourceAccessType;
  name: string;
  url: string | null;
  search_term: string | null;
  last_updated: string | null; // Puede ser null si no hay registros
  status: "success" | "error" | "warning" | null; // Añadido "no-records"
}

export const ResourceAccessLabels: {
  [key in ResourceAccessType]: { labelShort: string; description: string };
} = {
  "manual-input": {
    labelShort: "Subida Manual",
    description: "Subir archivo manualmente",
  },
  "direct-link": {
    labelShort: "Link de Descarga",
    description: "Enlace directo de descarga",
  },
  "site-link-search": {
    labelShort: "Scraping",
    description: "Scraping: Búsqueda en enlace del sitio",
  },
  "site-row-search": {
    labelShort: "Scraping",
    description: "Scraping: Búsqueda por filas en el sitio",
  },
};
