import { useLocation, Link } from "react-router-dom"; // Importar useLocation para obtener la ruta actual
import { visualizationCategories } from "src/config/visualizationConfig"; // Importamos visualizationCategories
import { IoIosArrowRoundForward } from "react-icons/io";
import { useReadingMode } from "src/context/ReadingModeContext";
// import React, { useEffect } from "react";
// import { useGeographicSelection } from "src/context/GeographicSelectionContext";

const VisualizationCategoryMenu = () => {
  // const { selectedUnits } = useGeographicSelection();
  // const navigate = useNavigate(); // Hook para navegar
  const location = useLocation(); // Hook para obtener la ruta actual

  const { isReadingMode } = useReadingMode();

  // // Filtrar solo regiones seleccionadas
  // const selectedRegions = selectedUnits.filter(
  //   (unit) => unit.type === "region"
  // );

  // // Mostrar regiones seleccionadas por consola cuando cambien
  // useEffect(() => {
  //   if (selectedRegions.length > 0) {
  //     console.log(
  //       "Regiones seleccionadas:",
  //       selectedRegions.map((unit) => unit.code).join(", ")
  //     );
  //   }
  // }, [selectedRegions]);

  // Función para manejar el clic en una tarjeta
  // const handleCardClick = (
  //   categoryKey: string,
  //   firstVisualizationKey: string
  // ) => {
  //   navigate(`/analyst/analytics/${categoryKey}/${firstVisualizationKey}`);
  // };
  //hide on timeline page and predict page
  if (
    location.pathname.includes("/analyst/timeline") ||
    location.pathname.includes("/analyst/predict")
  ) {
    return null;
  }

  return (
    <div className="fixed w-full top-4 landscape:top-8 h-20 top-0 left-0 z-40">
      <div
        className="px-4 scroll-px-4 flex gap-4 overflow-x-auto w-full h-full landscape:grid landscape:grid-cols-4 landscape:gap-4 landscape:overflow-x-visible no-scrollbar"
        style={{ scrollSnapType: "x mandatory" }}
      >
        {visualizationCategories.map((category, index) => {
          if (category.visualizations.length === 0) return null;
          const firstVisualizationKey = category.visualizations[0].key;

          // Verificar si la ruta actual corresponde a la categoría seleccionada
          const isActive = location.pathname.includes(
            `/analyst/analytics/${category.key}`
          );

          return (
            <Link
              key={index}
              className={`relative flex-shrink-0 w-[60vw] h-full rounded-2xl  landscape:hover:-translate-y-2 transform transition-all duration-700
                    landscape:w-full ${
                      isActive ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
              style={{
                scrollSnapAlign: "start",
                backgroundColor: isActive
                  ? "transparent"
                  : isReadingMode
                  ? "white"
                  : category.theme.backgroundColor,
                color: category.theme.textColor,
                pointerEvents: isActive ? "none" : "auto", // Desactivar clic si ya estamos en la categoría
                border: !isReadingMode
                  ? "1px solid transparent"
                  : isActive
                  ? "1px solid transparent"
                  : "1px solid #000",
              }}
              to={`/analyst/analytics/${category.key}/${firstVisualizationKey}`}
              // onClick={() =>
              //   !isActive &&
              //   handleCardClick(category.key, firstVisualizationKey)
              // } // Solo navegar si no está activa
            >
              {/* Cantidad de visualizaciones */}
              <div className="absolute top-2 right-2 flex items-center space-x-1">
                <IoIosArrowRoundForward
                  className={`text-4xl transform transition-transform duration-300 ${
                    isActive ? "rotate-90" : "rotate-0"
                  }`}
                />
                <div
                  className="rounded-full w-7 h-7 flex items-center justify-center text-base"
                  style={{
                    backgroundColor: isReadingMode
                      ? category.theme.backgroundColor
                      : "rgb(239 68 68)",
                    color: isReadingMode ? category.theme.textColor : "white",
                  }}
                >
                  {category.visualizations.length}
                </div>
              </div>

              {/* Título */}
              <div className="absolute bottom-2 left-4">
                <h2 className="text-xs lg:text-sm xl:text-base font-normal max-w-32 lg:max-w-48">
                  {category.name}
                </h2>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default VisualizationCategoryMenu;
