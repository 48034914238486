import React, { useRef } from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import useScrollMask from "src/hooks/useScrollMask"; // Activa el scroll con máscara

interface AdminLayoutProps {
  header?: string | React.ReactNode;
  message?: string;
  children: React.ReactNode;
  centerContent?: boolean;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({
  header,
  children,
  message,
  centerContent = false,
}) => {
  const scrollableParentContainer = useRef<HTMLDivElement>(null);

  // Activar el efecto de scroll "bonito" con máscara
  useScrollMask(scrollableParentContainer);

  return (
    <div
      className="relative inset-0 w-screen h-dvh flex flex-col bg-[#FFFAF5] text-black"
      style={{
        transition: "background-color 700ms, color 700ms", // Transición de color
      }}
    >
      {/* Header fijo en la parte superior */}
      {header &&
        ((typeof header === "string" && header !== "") ||
          typeof header !== "string") && (
          <div className="p-5 sm:pt-12 pb-2">
            <div className="max-w-screen-sm mx-auto">
              {typeof header === "string" ? (
                <h1 className="text-2xl sm:text-4xl text-center font-medium">
                  {header}
                </h1>
              ) : (
                header
              )}
            </div>
          </div>
        )}
      {/* Contenido del fullscreen que toma todo el espacio restante y permite scroll */}
      <div className="font-sans  flex-1 pb-20 sm:pb-28 sm:p-7">
        {/* Cuerpo scrollable centrado */}
        <div
          ref={scrollableParentContainer}
          className="relative w-full h-full max-w-screen-xl mx-auto overflow-x-unset overflow-y-auto no-scrollbar"
        >
          {/* Cuerpo scrollable con padding adicional para la barra de navegación */}
          <div
            className={
              "absolute w-full min-h-full flex flex-col justify-start p-5 " +
              (centerContent ? "justify-center" : "justify-start")
            }
          >
            <div className="w-full">{children}</div>
          </div>
        </div>
      </div>

      {message && (
        <div className="hidden landscape:block absolute top-10 left-10 text-left max-w-sm">
          <p className="text-md mb-2 font-regular">{message}</p>
          <HiOutlineArrowRight size={30} className="ml-2" />
        </div>
      )}
    </div>
  );
};

export default AdminLayout;
