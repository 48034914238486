import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Tipo de la unidad seleccionada
export type GeographicUnitType = "region" | "commune";

// Estructura de una selección geográfica
export interface GeographicSelection {
  type: GeographicUnitType;
  code: number;
}

// Definir el tipo para el contexto
interface GeographicSelectionContextType {
  selectedUnits: GeographicSelection[];
  setSelectedUnits: React.Dispatch<React.SetStateAction<GeographicSelection[]>>;
}

// Valor inicial del contexto
const defaultValue: GeographicSelectionContextType = {
  selectedUnits: [],
  setSelectedUnits: () => {}, // Proporciona un valor predeterminado de función vacía
};

// Crear el contexto con el valor predeterminado
const GeographicSelectionContext =
  createContext<GeographicSelectionContextType>(defaultValue);

// Función para serializar las selecciones en parámetros separados (regions y communes)
const serializeSelections = (
  selections: GeographicSelection[]
): { regions: string; communes: string } => {
  const regions = selections
    .filter((selection) => selection.type === "region")
    .map((selection) => selection.code)
    .join(",");

  const communes = selections
    .filter((selection) => selection.type === "commune")
    .map((selection) => selection.code)
    .join(",");

  return { regions, communes };
};

// Función para deserializar los query params en el estado de `selectedUnits`
const deserializeSelections = (
  query: URLSearchParams
): GeographicSelection[] => {
  const regionsParam = query.get("regions");
  const communesParam = query.get("communes");

  const regions = regionsParam
    ? regionsParam
        .split(",")
        .map((code) => ({
          type: "region" as GeographicUnitType,
          code: Number(code),
        }))
    : [];
  const communes = communesParam
    ? communesParam
        .split(",")
        .map((code) => ({
          type: "commune" as GeographicUnitType,
          code: Number(code),
        }))
    : [];

  return [...regions, ...communes];
};
// Proveedor del contexto
export const GeographicSelectionProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Inicializa el estado a partir de los query params de la URL
  const queryParams = new URLSearchParams(location.search);
  const initialSelections = deserializeSelections(queryParams);

  const [selectedUnits, setSelectedUnits] =
    useState<GeographicSelection[]>(initialSelections);

  // Efecto para sincronizar los cambios de `selectedUnits` con los query params
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const { regions, communes } = serializeSelections(selectedUnits);

    if (regions) {
      queryParams.set("regions", regions);
    } else {
      queryParams.delete("regions");
    }

    if (communes) {
      queryParams.set("communes", communes);
    } else {
      queryParams.delete("communes");
    }

    navigate({ search: queryParams.toString() }, { replace: true });
  }, [selectedUnits, navigate, location.search]);

  return (
    <GeographicSelectionContext.Provider
      value={{ selectedUnits, setSelectedUnits }}
    >
      {children}
    </GeographicSelectionContext.Provider>
  );
};

// Hook para acceder al contexto
export const useGeographicSelection = () => {
  return useContext(GeographicSelectionContext);
};
