import { useEffect, useRef, useMemo } from "react";
import { createRoot } from "react-dom/client";
import mapboxgl from "mapbox-gl";
import useSupercluster from "use-supercluster";

// Contexts
import { useMap } from "src/context/MapContext";

// Types
// import { FeatureCollection } from "@customTypes/GlobalTypes";
import { FeatureCollection, GeoJsonProperties, Point } from "geojson";

interface ClusteredMarkersManagerProps<T extends GeoJsonProperties | null> {
  data: FeatureCollection<Point, T>;
  idExtractor?: (properties: T) => string | number;
  visible?: boolean;
  clusterRadius?: number;
  clusterMaxZoom?: number;
  renderCluster: (point_count: number, total: number) => JSX.Element;
  renderMarker: (properties: T) => JSX.Element;
  onClickMarker: (properties: T, coordinates: [number, number]) => void;
}

const ClusteredMarkersManager = <T extends GeoJsonProperties | null>({
  data,
  idExtractor,
  visible = true,
  clusterRadius = 75,
  clusterMaxZoom = 12,
  renderCluster,
  renderMarker,
  onClickMarker,
}: ClusteredMarkersManagerProps<T>) => {
  const { mapRef } = useMap();
  const markersRef = useRef<Record<string, mapboxgl.Marker>>({});

  const points = useMemo(() => {
    if (!data) return [];
    return data.features.map((feature) => ({
      type: "Feature",
      properties: {
        id: feature.id,
        cluster: false,
        cluster_id: idExtractor ? idExtractor(feature.properties) : feature.id,
        ...feature.properties,
      },
      geometry: feature.geometry,
    }));
  }, [data, idExtractor]);

  const bounds: [number, number, number, number] = (mapRef.current
    ?.getBounds()
    ?.toArray()
    .flat() as [number, number, number, number]) || [-180, -90, 180, 90];

  // const { clusters, supercluster } = useSupercluster({
  //   points,
  //   bounds,
  //   zoom: Math.floor(mapRef.current ? mapRef.current.getZoom() : 0),
  //   options: { radius: clusterRadius, maxZoom: clusterMaxZoom },
  // });
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: Math.floor(mapRef.current ? mapRef.current.getZoom() : 0),
    options: { radius: clusterRadius, maxZoom: clusterMaxZoom, minZoom: 4 },
  });
  // console.log(
  //   "ZOOM",
  //   Math.floor(mapRef.current ? mapRef.current.getZoom() : 0)
  // );

  const expandCluster = (cluster: any) => {
    if (!supercluster || !mapRef.current) return;
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      20
    );
    const [longitude, latitude] = cluster.geometry.coordinates;
    mapRef.current.easeTo({
      center: [longitude, latitude],
      zoom: expansionZoom,
    });
  };

  useEffect(() => {
    if (!mapRef.current) return;

    if (!visible) {
      Object.keys(markersRef.current).forEach((key) => {
        markersRef.current[key].remove();
        delete markersRef.current[key];
      });
      return;
    }

    Object.keys(markersRef.current).forEach((key) => {
      if (
        !clusters.find((c) =>
          c.properties.cluster
            ? `cluster-${c.id}` === key
            : `marker-${c.properties.cluster_id}` === key
        )
      ) {
        markersRef.current[key].remove();
        delete markersRef.current[key];
      }
    });

    clusters.forEach((cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { cluster: isCluster } = cluster.properties;
      const key = isCluster
        ? `cluster-${cluster.id}`
        : `marker-${cluster.properties.cluster_id}`;

      if (!markersRef.current[key]) {
        const element = document.createElement("div");
        if (isCluster) {
          createRoot(element).render(
            <div onClick={() => expandCluster(cluster)}>
              {renderCluster(cluster.properties.point_count, points.length)}
            </div>
          );
        } else {
          createRoot(element).render(
            <div
              onClick={() =>
                onClickMarker(cluster.properties, [longitude, latitude])
              }
            >
              {renderMarker(cluster.properties)}
            </div>
          );
        }

        const marker = new mapboxgl.Marker(element)
          .setLngLat([longitude, latitude])
          .addTo(mapRef.current!.getMap());

        markersRef.current[key] = marker;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusters, visible, mapRef, renderCluster, renderMarker]);

  const cleanupMarkers = () => {
    Object.keys(markersRef.current).forEach((key) => {
      markersRef.current[key].remove();
      delete markersRef.current[key];
    });
  };

  useEffect(() => {
    return () => {
      cleanupMarkers();
    };
  }, []);

  return null;
};

export default ClusteredMarkersManager;
