// src/views/Login.tsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdminLayout from "src/admin/layout/AdminLayout";

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await axios.post("/api/login", {
        username,
        password,
      });

      const { AccessToken, ExpiresIn, RefreshToken } = response.data;

      // Calcular el tiempo de expiración en milisegundos
      const expirationTime = Date.now() + ExpiresIn * 1000;

      // Guardar los tokens y el tiempo de expiración en localStorage
      localStorage.setItem("accessToken", AccessToken);
      localStorage.setItem("refreshToken", RefreshToken);
      localStorage.setItem("accessTokenExpiration", expirationTime.toString());

      // Redirigir al panel de administración
      navigate("/admin");
    } catch (err) {
      setError("Credenciales incorrectas o error en el servidor.");
    }
  };

  return (
    <AdminLayout
      message="Ingresa tu nombre de usuario y contraseña para acceder al Administrador de Datos"
      centerContent={true}
    >
      <div className="mx-auto w-full max-w-md p-8 border border-black rounded-3xl bg-transparent">
        <h1 className="text-2xl font-light mb-6 uppercase text-left">Login</h1>
        <hr className="mb-6 border-gray-300" />
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleLogin} className="space-y-6">
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full px-6 py-3 rounded-full bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-black"
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-6 py-3 rounded-full bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-black"
          />
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-6 py-3 bg-black text-white rounded-full hover:bg-gray-800 transition"
            >
              Iniciar sesión
            </button>
          </div>
        </form>
        <hr className="mt-6 border-gray-300" />
      </div>
    </AdminLayout>
  );
};

export default LoginPage;
