// src/utils/filtersUtils.ts

import { AvailableFilter } from "src/types/graph";

// Función para construir el cuerpo del request

export const updateFilters = (
  filters: AvailableFilter[],
  filterKey: string,
  selected: string | number | Array<string | number> | null
): AvailableFilter[] => {
  return filters.map((filter) => {
    if (filter.key === filterKey) {
      return { ...filter, selected };
    }
    return filter;
  });
};

// { filters: { [key: string]: any } } = { filters: {} };
export const buildFiltersBody = (
  filters: AvailableFilter[] // booleano opcional para incluir 'filters'
): { filters: { [key: string]: any } } => {
  const body = filters.reduce((acc: Record<string, any>, filter) => {
    if (filter.selected != null) {
      acc[filter.key] = filter.selected;
    }
    return acc;
  }, {});

  return { filters: body }; // Incluir o no la clave 'filters'
};
