// src/components/UserProfileSelector.tsx

import React from "react";
import BubbleChart from "src/components/BubbleChart";
import { useNavigate } from "react-router-dom";

const UserProfileSelector: React.FC = () => {
  const navigate = useNavigate();

  // const profiles = [
  //   {
  //     id: "tourist",
  //     label: "Quiero conocer atractivos y destinos",
  //     theme: {
  //       // backgroundColor: "#4A90E2",
  //       backgroundColor: "#fff",
  //       // auxiliaryColor: "#357ABD",
  //       textColor: "black",
  //     },
  //   },
  //   {
  //     id: "analyst",
  //     label: "Quiero ver estadísticas",
  //     theme: {
  //       // backgroundColor: "#E94E77",
  //       backgroundColor: "#fff",
  //       // auxiliaryColor: "#D52B56",
  //       textColor: "black",
  //     },
  //   },
  // ];

  // const handleNodeClick = (node: any) => {
  //   if (node.id === "tourist") {
  //     navigate("/tourist");
  //   } else if (node.id === "analyst") {
  //     navigate("/analyst");
  //   }
  // };

  return (
    <div className="z-20 fixed inset-0 w-screen h-screen bg-black/50 flex">
      <div className="w-1/2 h-full relative">
        <BubbleChart
          currentNodes={[
            {
              id: "toruist",
              label: "Quiero conocer atractivos y destinos",
              theme: {
                backgroundColor: "white",
                textColor: "black",
              },
            },
          ]}
          onNodeClick={() => {
            navigate("/tourist");
          }}
        />
      </div>
      <div className="w-1/2 h-full relative">
        <BubbleChart
          currentNodes={[
            {
              id: "analyst",
              label: "Quiero ver estadísticas",
              theme: {
                backgroundColor: "white",
                textColor: "black",
              },
            },
          ]}
          onNodeClick={() => {
            navigate("/analyst");
          }}
        />
      </div>

      {/* <div className="flex flex-col items-center justify-center h-full">
        <Link to="/tourist" className="btn btn-primary bg-white">
          Quiero conocer atractivos y destinos
        </Link>
        <Link to="/analyst" className="btn btn-primary bg-white">
          Quiero ver estadísticas
        </Link>
      </div> */}
    </div>
  );
};

export default UserProfileSelector;
