import { useState } from "react";
import axios from "axios";
import ClusteredMarkersManager from "src/components/ClusteredMarkersManager";
import { FeatureCollection, Point } from "geojson";
import Loader from "src/components/Loader"; // Asegúrate de importar tu componente Loader
import { Popup } from "react-map-gl";
import { AttractionProperties } from "../Attractions/TouristView";

interface AttractionInfo {
  [name: string]: any;
  address: string;
  administers: string;
  category: string;
  cc: string;
  commune: string;
  cr: string;
  demand: string;
  description: string;
  distcc: number;
  distcr: number;
  id: number;
  latitude: number;
  locality: string;
  longitude: number;
  name: string;
  ownership: string;
  phone: string;
  province: string;
  province_code: number;
  region_code: number;
  seasonal: string;
  services: string;
  state_1: string;
  subtype: string;
  tourism_use: string;
  type: string;
  urban_location: string;
}
interface AttractionsManagerProps {
  mapData: FeatureCollection<Point, AttractionProperties>;
}

const FILL_COLOR = "#fff";
const BOX_SHADOW = "0px 0px 10px 5px rgba(255,255,255,0.55)";

const AttractionsManager: React.FC<AttractionsManagerProps> = ({ mapData }) => {
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  const [popupInfo, setPopupInfo] = useState<AttractionProperties | null>(null);
  const [popupIsLoading, setPopupIsLoading] = useState<boolean>(false);

  const handleMarkerClick = (
    properties: AttractionProperties,
    _coordinates: [number, number]
  ) => {
    if (!properties.id) return;

    setPopupInfo(null);
    setPopupIsLoading(true);
    requestAnimationFrame(() => {
      setPopupInfo({
        // ...properties,
        latitude: _coordinates[1],
        longitude: _coordinates[0],
      });

      axios
        .get<AttractionInfo>("/api/attractions/" + properties.id)
        .then((response) => {
          setPopupInfo(response.data);
        })
        .catch((error) => {
          console.error("Error fetching attraction details", error);
        })
        .finally(() => {
          setPopupIsLoading(false);
        });
    });
  };

  const renderCluster = (point_count: number, total: number) => {
    // const size = 50 + (point_count / total) * 100; // Cálculo del tamaño del cluster
    const size = 30 + (point_count / 1800) * 50; // Cálculo del tamaño del cluster
    return (
      <div
        className="rounded-full text-xs flex justify-center items-center cursor-pointer p-1 font hover:scale-125 transition-transform duration-500 ease-in-out"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          background: FILL_COLOR,
          borderRadius: "50%",
          color: "black",
          boxShadow: BOX_SHADOW,
        }}
      >
        {point_count}
      </div>
    );
  };

  const renderMarker = (_properties: AttractionProperties) => (
    <div
      className="hover:scale-125 transition-transform duration-500 ease-in-out"
      style={{
        width: "20px", // Tamaño explícito
        height: "20px", // Tamaño explícito
        backgroundColor: FILL_COLOR,
        borderRadius: "50%",
        cursor: "pointer",
        boxShadow: BOX_SHADOW,
      }}
    />
  );

  return (
    <>
      <ClusteredMarkersManager<AttractionProperties>
        data={mapData}
        renderCluster={renderCluster}
        renderMarker={renderMarker}
        onClickMarker={handleMarkerClick}
      />

      {popupInfo && (
        <Popup
          latitude={popupInfo.latitude}
          longitude={popupInfo.longitude}
          onClose={() => setPopupInfo(null)}
        >
          <div className="relative p-3 w-[300px]  h-auto min-h-40 max-w-(min(80vw,300px)] select-text">
            {popupIsLoading && <Loader color="white" />}
            <h3 className="text-2xl font-semibold capitalize mb-3 leading-7">
              {popupInfo.name?.toLowerCase()}
            </h3>
            <p className="font-light text-base leading-4">
              {popupInfo.description}
            </p>
          </div>
        </Popup>
      )}
    </>
  );
};

export default AttractionsManager;
