import { useEffect, useCallback } from "react";

function useScrollMask(ref: React.RefObject<HTMLElement>) {
  const HEIGHT_MAX = 5;

  // Función para aplicar la máscara basada en el scroll
  const setMask = useCallback(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    const isScrollable = el.scrollHeight > el.clientHeight;
    // Si no es scrollable, no aplicar máscara
    if (!isScrollable) {
      el.style.maskImage = "none";
      el.style.display = "block";
      el.style.alignItems = "center";
      return;
    }

    el.style.alignItems = "flex-start";

    // Calcular el porcentaje de desplazamiento
    const scrollPercentage = el.scrollTop / (el.scrollHeight - el.clientHeight);

    // Definir el tamaño de las máscaras superior e inferior usando funciones ease
    const topMaskSize = Math.min(
      HEIGHT_MAX,
      easeOut(scrollPercentage) * HEIGHT_MAX
    );
    const bottomMaskSize = Math.min(
      HEIGHT_MAX,
      (1 - easeIn(scrollPercentage)) * HEIGHT_MAX
    );

    // Aplicar el valor de la máscara
    const maskValue = `linear-gradient(
      to bottom,
      transparent 0,
      rgba(0, 0, 0, 0.2) calc(${topMaskSize}rem * 0.5),
      black ${topMaskSize}rem,
      black calc(100% - ${bottomMaskSize}rem),
      rgba(0, 0, 0, 0.2) calc(100% - calc(${bottomMaskSize}rem * 0.5)),
      transparent 100%
    )`;

    // Aplicar la máscara
    el.style.maskImage = `-webkit-${maskValue}`;
    el.style.maskImage = maskValue;
  }, [ref]);

  useEffect(() => {
    const attachListeners = () => {
      const el = ref.current;
      if (!el) {
        return;
      }

      el.style.overflow = "auto"; // Hacer que el elemento sea scrollable
      setMask(); // Aplicar la máscara al cargar

      const handleScroll = () => setMask();
      const handleResize = () => setMask();
      const handleLoad = () => setMask();

      // Agregar event listeners
      el.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
      window.addEventListener("load", handleLoad);

      // Cleanup: Eliminar event listeners cuando el componente se desmonte
      return () => {
        el.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("load", handleLoad);
      };
    };

    // Utilizar requestAnimationFrame para aplicar los listeners después de que el DOM esté listo
    const rafId = requestAnimationFrame(() => {
      if (ref.current) {
        attachListeners();
      }
    });

    // Limpiar el requestAnimationFrame al desmontar el componente
    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [ref, setMask, ref.current?.scrollHeight, ref.current?.clientHeight]);

  // Función de easing para suavizar la transición de la máscara al hacer scroll
  const easeOut = (x: number) => {
    return 1 - Math.pow(1 - x, 2);
  };

  const easeIn = (x: number) => {
    return x * x;
  };
}

export default useScrollMask;
