import Select from "react-select";
import { AvailableFilter } from "src/types/graph"; // Importar el tipo AvailableFilter para el filtro completo
import { VisualizationTheme } from "src/types/visualization";

// Tipado de las props del componente FilterSelect
interface FilterSelectProps {
  filter: AvailableFilter; // Recibe el filtro completo
  onFilterChange: (
    filterKey: string,
    selected: string | number | Array<string | number> | null
  ) => void; // Cambia el valor del filtro
  theme?: VisualizationTheme; // Tema de la visualización
}

const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isMulti
      ? "transparent"
      : state.hasValue
      ? "#000"
      : "transparent",
    borderColor: "#000",
    borderRadius: "9999px",
    minHeight: "32px",
    height: "32px",
    padding: state.isMulti && state.hasValue ? "0 4px" : "0 12px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    borderWidth: "1px",
    color: state.isMulti || !state.hasValue ? "#000" : "#fff",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: "0.875rem",
    color:
      state.isMulti || !state.hasValue
        ? "rgba(0, 0, 0, 0.6)"
        : "rgba(255, 255, 255, 0.6)",
    display: "flex",
    alignItems: "center",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#fff",
    display: "flex",
    alignItems: "center",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: "12px",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    margin: "0 2px",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "#fff",
    fontSize: "0.75rem",
    lineHeight: "1rem",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    borderRadius: "50%",
    padding: "0",
    marginLeft: "4px",
    color: "#fff",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: "0",
    marginLeft: "4px",
    alignItems: "center",
    display: "flex",
    color: state.isMulti ? "#000" : "#fff",
    cursor: "pointer",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: "#ffdef4",
    border: "1px solid #000",
    borderRadius: "0 ",
    color: "#000",
    boxShadow: "none",
    right: "0",
    width: "max-content",
    minWidth: "100%", // Puedes ajustar este tamaño según sea necesario
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#000" : "transparent",
    color: state.isFocused ? "#fff" : "#000",
    "&:active": {
      backgroundColor: "#000",
      color: "#fff",
    },
  }),
};

const FilterSelect: React.FC<FilterSelectProps> = ({
  filter,
  onFilterChange,
  theme,
}) => {
  const handleSelectChange = (selectedOption: any) => {
    if (filter.multiple) {
      const selectedValues = Array.isArray(selectedOption)
        ? selectedOption.map((option) => option.value)
        : [];
      onFilterChange(filter.key, selectedValues);
    } else {
      onFilterChange(filter.key, selectedOption?.value || null);
    }
  };

  return (
    <div className="inline-flex items-center hidden-for-screenshotx xremove-for-screenshot">
      <Select
        styles={{
          ...customSelectStyles,
          menu: (base: any) => ({
            ...base,
            backgroundColor: theme?.backgroundColor || "#fff",
            border: "1px solid #000",
            borderRadius: "0 ",
            color: "#000",
            boxShadow: "none",
            right: window.innerWidth > 500 ? "0" : "unset",
            left: window.innerWidth < 500 ? "0" : "unset",
            width: "max-content",
            minWidth: "100%",
            maxWidth: "50vw",
          }),
        }}
        className="bg-transparent text-sm h-8 rounded-full"
        options={filter.values.map((value) => ({
          value: value.key,
          label: value.label,
        }))}
        noOptionsMessage={() => "No hay opciones disponibles"}
        onChange={handleSelectChange}
        value={
          filter.selected != null
            ? filter.multiple
              ? (filter.selected as Array<string | number>).map((key) => ({
                  value: key,
                  label: filter.values.find((v) => v.key === key)?.label || key,
                }))
              : {
                  value: filter.selected,
                  label:
                    filter.values.find((v) => v.key === filter.selected)
                      ?.label || filter.selected,
                }
            : null
        }
        isMulti={filter.multiple}
        placeholder={filter.label}
        isClearable={filter.required ? false : true}
        isSearchable={false}
      />
    </div>
  );
};

export default FilterSelect;
