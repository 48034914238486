// src/components/TimelineViewer/TimelineViewer.tsx

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Loader from "src/components/Loader"; // Ajusta la ruta si es necesario
import "./TimelineViewer.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  DataNodeConfig,
  timelineConfig,
  TimelineData,
} from "src/config/timelineConfig";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import { formatForRacingBars, getShortYearMonth } from "src/utils/timeUtils";
import RacingBars from "racing-bars/react";
import { Data, Race } from "racing-bars";
import { TickDetails } from "racing-bars/lib/index";
import TimelinePicker, { Option } from "./TimelinePicker"; // Importa el nuevo componente
import { SingleValue } from "react-select";
import FullscreenLayout from "src/layouts/FullscreenLayout";
import YearTimelineControlBar, {
  YearTimelineRef,
} from "./controllers/YearTimelineControlBar";
import MonthTimelineControlBar, {
  MonthTimelineRef,
} from "./controllers/MonthTimelineControlBar";
import DataSource from "src/components/charts/DataSource";
import FilterSelect from "src/components/charts/FilterSelect";
import { AvailableFilter } from "src/types/graph";
import { buildFiltersBody, updateFilters } from "src/utils/filterUtils";
import { VisualizationTheme } from "src/types/visualization";
import { formatLocale, formatDefaultLocale } from "d3";
import useNumberFormattingFix from "./useNumberFormattingFix";

const visualizationTheme: VisualizationTheme = {
  backgroundColor: "#fff",
  textColor: "black",
};

const TimelineViewer: React.FC = () => {
  const { variableId, graphId } = useParams<{
    variableId: string;
    graphId: string;
  }>();

  const navigate = useNavigate(); // Inicializar navigate

  const [timelineData, setTimelineData] = useState<TimelineData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Estado para manejar si está reproduciendo o en pausa
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const racerRef = useRef<Race | null>(null);
  const monthTimelineRef = useRef<MonthTimelineRef | null>(null);
  const yearTimelineRef = useRef<YearTimelineRef | null>(null);

  // Variables para almacenar el nodo padre y el nodo hijo seleccionado
  const [parentNode, setParentNode] = useState<DataNodeConfig | null>(null);
  const [selectedNode, setSelectedNode] = useState<DataNodeConfig | null>(null);

  const [filters, setFilters] = useState<AvailableFilter[] | null>(null);
  const racerContainerRef = useRef<HTMLDivElement>(null); // Ref para el RacingBars
  useNumberFormattingFix(racerContainerRef);

  useEffect(() => {
    // Encontrar el nodo padre basado en variableId
    const node = timelineConfig.find((node) => node.id === variableId);
    setParentNode(node || null);

    if (!node) {
      console.error("Variable ID no encontrado.");
      navigate("/analyst"); // Redirigir al root
      return;
    }

    // Encontrar el nodo hijo basado en graphId
    const childNode = node.children?.find((child) => child.id === graphId);
    setSelectedNode(childNode || null);

    if (!childNode) {
      console.error("Graph ID no encontrado.");
      navigate("/analyst"); // Redirigir al root
      return;
    }

    const endpoint = childNode.endpoint; // Usar el endpoint del childNode
    if (!endpoint) {
      console.error("Endpoint no encontrado para los IDs proporcionados.");
      setError("Datos no encontrados para la selección actual.");
      setLoading(false); // Asegúrate de detener la carga
      return;
    }

    setLoading(true); // Iniciar la carga
    setTimelineData(null);

    axios
      .post<TimelineData>(endpoint)
      .then((response) => {
        const data: TimelineData = response.data;
        setTimelineData(data); // Guardar los datos originales
        setError(null); // Limpiar errores anteriores
        if (data.availableFilters) setFilters(data.availableFilters);
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
        setError("Error al obtener los datos. Por favor, intenta nuevamente.");
      })
      .finally(() => {
        setLoading(false); // Finalizar la carga
      });
  }, [variableId, graphId, navigate]);

  const onDateChange = (date: TickDetails) => {
    const [year, month] = date.date.split("-").map(Number);
    monthTimelineRef.current?.setCurrentDate(year, month);
    yearTimelineRef.current?.setCurrentYear(year);
  };

  // Crear opciones para el TimelinePicker, excluyendo la opción seleccionada
  const options: Option[] =
    parentNode?.children
      ?.filter((child) => child.id !== graphId)
      .map((child) => ({
        value: child.id,
        label: child.label,
      })) || [];

  const selectedOption = selectedNode
    ? { value: selectedNode.id, label: selectedNode.label }
    : null;

  // Manejar cambio de selección en TimelinePicker
  const handleOptionChange = (selected: SingleValue<Option>) => {
    if (selected) {
      const selectedGraphId = selected.value;
      navigate(`/analyst/timeline/${variableId}/${selectedGraphId}`);
    }
  };

  // Función para alternar entre play y pause
  const togglePlay = () => {
    if (racerRef.current) {
      if (racerRef.current.isRunning()) {
        racerRef.current.pause();
      } else {
        racerRef.current.play();
      }
    }
  };

  // Memorizar RacingBars
  const racingBars = useMemo(() => {
    if (!timelineData) {
      racerRef.current?.destroy();
      racerRef.current = null;
      return null;
    }

    let hashElementID = "";
    if (timelineData.availableFilters) {
      const activeFilters = buildFiltersBody(timelineData.availableFilters);
      hashElementID = JSON.stringify(activeFilters);
      hashElementID = hashElementID.replace(/[^a-zA-Z0-9]/g, "");
    }
    //find the most large word in the data
    let maxLabelLength = 0;
    timelineData.data.forEach((element: Data) => {
      if (element.name.length > maxLabelLength) {
        maxLabelLength = element.name.length;
      }
    });
    let maxNumberLength = 0;
    timelineData.data.forEach((element: Data) => {
      if (element.value.toString().length > maxNumberLength) {
        // casto to int to avoid the decimal part
        const intPart = parseInt(element.value.toString());
        maxNumberLength = intPart.toString().length;
      }
    });
    if (maxLabelLength >= 18) {
      maxLabelLength = 18;
      timelineData.data.forEach((element: Data) => {
        if (element.name.toString().length > 18) {
          element.name = element.name.toString().substring(0, 15) + "...";
        }
      });
    }
    const spanishLocale: d3.FormatLocaleDefinition = {
      decimal: ",",
      thousands: ".",
      grouping: [3],
      currency: ["€", ""], // Exactamente 2 elementos en la tupla
    };

    formatLocale(spanishLocale);
    formatDefaultLocale(spanishLocale);
    // format(",.2f")(1234567.89); // "1.234.567,89"
    return (
      <RacingBars
        elementId={hashElementID}
        data={timelineData?.data || []}
        autorun={true}
        // labelsWidth={380}v
        labelsWidth={maxLabelLength * 6.5}
        labelsPosition={"outside"}
        valueDecimals={0}
        keyboardControls={false}
        controlButtons="none"
        mouseControls={false}
        marginRight={maxNumberLength > 6 ? maxNumberLength * 6.5 : 0}
        topN={10}
        colorMap={["#000"]}
        tickDuration={
          timelineData?.time.interval_type === "yearly" ? 1000 : 500
        }
        dateCounter={(
          currentDate: string,
          dataSlice: Data[],
          allData: string[]
        ) => {
          if (timelineData?.time.interval_type === "monthly") {
            const [year, month] = currentDate.split("-").map(Number);
            return `${getShortYearMonth(year, month)}`;
          } else if (timelineData?.time.interval_type === "yearly") {
            const [year] = currentDate.split("-").map(Number);
            return `${year}`;
          }
          return currentDate;
        }}
        callback={(racer: Race) => {
          if (racerRef.current) {
            racerRef.current.destroy();
          }
          racerRef.current = racer;
          racer.on("dateChange", onDateChange);
          racer.on("pause", () => {
            setIsPlaying(false);
          });
          racer.on("play", () => {
            setTimeout(() => {
              setIsPlaying(true);
            }, 100);
          });
        }}
      />
    );
  }, [timelineData]);

  const renderYearTimelineControlBar = useMemo(() => {
    if (!timelineData) return null;

    return (
      <YearTimelineControlBar
        ref={yearTimelineRef}
        startYear={timelineData.time.start_year}
        endYear={timelineData.time.end_year}
        onSelect={(year) => {
          racerRef.current?.setDate(formatForRacingBars(year));
        }}
      />
    );
  }, [timelineData]);

  const renderMonthTimelineControlBar = useMemo(() => {
    if (!timelineData) return null;

    return (
      <MonthTimelineControlBar
        ref={monthTimelineRef}
        startYear={timelineData.time.start_year}
        endYear={timelineData.time.end_year}
        startMonth={timelineData.time.start_month}
        endMonth={timelineData.time.end_month}
        onSelect={(year, month) => {
          racerRef.current?.setDate(formatForRacingBars(year, month));
        }}
      />
    );
  }, [timelineData]);

  const handleFilterChange = useCallback(
    async (
      filterKey: string,
      selected: string | number | Array<string | number> | null
    ) => {
      if (!filters || !selectedNode || !selectedNode.endpoint) return;
      setLoading(true);
      setTimelineData(null);
      const newFilters = updateFilters(filters, filterKey, selected);
      setFilters(newFilters);
      try {
        const response = await axios.post<TimelineData>(
          selectedNode.endpoint,
          buildFiltersBody(newFilters)
        );
        setLoading(false);
        setTimelineData(response.data);
        if (response.data.availableFilters)
          setFilters(response.data.availableFilters);
      } catch (error) {
        console.error("Error fetching filtered attractions", error);
      }
    },
    [filters, selectedNode]
  );

  return (
    <FullscreenLayout
      header={
        <h1 className="text-lg sm:text-xl font-regular text-center">
          Quiero ver la línea de tiempo de{" "}
          <Link
            className="underline decoration-dotted underline-offset-4 font-medium"
            to="/analyst/timeline"
          >
            {parentNode?.label}
          </Link>{" "}
          con detalle en{" "}
          <TimelinePicker
            options={options}
            selectedOption={selectedOption}
            onChange={handleOptionChange}
          />
        </h1>
      }
    >
      {loading && <Loader color="black" />}

      {error && <div className="text-red-500 text-lg text-center">{error}</div>}

      {timelineData && (
        <div className="space-y-4">
          <div className="max-w-screen-lg mx-auto space-y-6">
            <div className="sm:flex justify-between items-start space-y-4 sm:space-y-0">
              <div />
              <div className="flex flex-wrap justify-start sm:justify-end gap-2">
                {filters &&
                  filters?.map((filter) => (
                    <FilterSelect
                      key={filter.key}
                      filter={filter}
                      onFilterChange={handleFilterChange}
                      theme={visualizationTheme}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="w-full overflow-hidden relative">
            <div ref={racerContainerRef} className="w-full md:w-full">
              {racingBars}
            </div>
          </div>

          <div className="max-w-screen-md mx-auto relative sm:mt-20">
            {timelineData.time.interval_type === "monthly"
              ? renderMonthTimelineControlBar
              : renderYearTimelineControlBar}
            <div
              onClick={togglePlay} // Asignar el manejador de clic
              className="absolute rounded-full bg-black w-10 h-10 transform -translate-x-1/2 left-1/2 lg:-left-5 top-[unset] -bottom-14 lg:top-2 sm:bottom-[unset] text-white flex justify-center items-center cursor-pointer hover:bg-gray-700 transition-colors duration-300 ease-in-out" // Estilos de hover
            >
              {isPlaying ? <IoIosPause size={24} /> : <IoIosPlay size={24} />}
            </div>
          </div>
        </div>
      )}

      {timelineData && selectedNode && (
        <div className="pb-4 mt-24 lg:mt-8 px-5">
          <DataSource source={parentNode?.source} className="mb-4" />
          <div className="text-justify opacity-75 text-xs p-3 ">
            {selectedNode.description}
          </div>
        </div>
      )}
    </FullscreenLayout>
  );
};

export default TimelineViewer;
