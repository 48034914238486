// src/components/GraphModeViewer.tsx

import React, { useState, useEffect, useRef, useCallback } from "react";
import ChartRenderer from "src/components/charts/ChartRenderer";
import FilterSelect from "src/components/charts/FilterSelect";
import MetricToggle from "src/components/charts/MetricToggle";
import Loader from "src/components/Loader";
import {
  VisualizationCategory,
  VisualizationConfig,
} from "src/types/visualization";
import {
  AvailableFilter,
  GraphDataResponse,
  GraphViewMode,
} from "src/types/graph";
import ViewModeSwitch from "src/components/charts/ViewModeSwitch";
import { PiSmileySad } from "react-icons/pi";
import useScrollMask from "src/hooks/useScrollMask";
import CategoryGraphListPanel from "./VisualizationListPanel";
import RegionFilterSelect from "src/components/charts/RegionFilterSelect";
import { useGeographicSelection } from "src/context/GeographicSelectionContext";
import { buildFiltersBody, updateFilters } from "src/utils/filterUtils";
import axios from "axios";
import DataSource from "src/components/charts/DataSource";
import { useNavigate } from "react-router-dom";
import VariableSelectMobile from "./VariableSelectMobile";

interface Props {
  categoryConfig: VisualizationCategory;
  visualizationConfig: VisualizationConfig;
}

const GraphModeViewer: React.FC<Props> = ({
  categoryConfig,
  visualizationConfig,
}) => {
  const [data, setData] = useState<GraphDataResponse | null>(null); // Ajustar el tipo si tienes uno más específico
  const [filters, setFilters] = useState<AvailableFilter[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { selectedUnits } = useGeographicSelection();
  const [viewMode, setViewMode] = useState<GraphViewMode>(
    visualizationConfig.defaultChart
  );
  const navigate = useNavigate();
  const { setSelectedUnits } = useGeographicSelection();
  const bodyContentRef = useRef(null);
  useScrollMask(bodyContentRef);

  // Función para obtener los datos usando axios

  // Fetch de los datos inicial
  useEffect(() => {
    if (!visualizationConfig) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const selectedRegions = selectedUnits
          .filter((unit) => unit.type === "region")
          .map((unit) => unit.code);

        const responseData = await axios.post<GraphDataResponse>(
          visualizationConfig.endpoint,
          {
            filters: {
              region_codes: selectedRegions,
            },
          }
        );

        setData(responseData.data);
        setFilters(responseData.data.availableFilters);

        setError(null);
      } catch (err) {
        // Puedes acceder al mensaje de error personalizado
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Error al obtener datos");
        }
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualizationConfig]);

  const handleFilterChange = useCallback(
    async (
      filterKey: string,
      selected: string | number | Array<string | number> | null
    ) => {
      if (!filters) return;

      let newFilters = updateFilters(filters, filterKey, selected);

      if (filterKey === "region_codes_mirrored") {
        // check if is a number
        if (typeof selected === "number") {
          newFilters = updateFilters(newFilters, "region_codes", [selected]);
          setSelectedUnits([{ type: "region", code: selected }]);
        } else {
          newFilters = updateFilters(newFilters, "region_codes", selected);
        }
      }
      setFilters(newFilters);
      setIsFilterLoading(true);
      try {
        const response = await axios.post<GraphDataResponse>(
          visualizationConfig.endpoint,
          buildFiltersBody(newFilters)
        );
        setData(response.data);
        setFilters(response.data.availableFilters);
        setIsFilterLoading(false);
      } catch (error) {
        console.error("Error fetching filtered attractions", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, visualizationConfig.endpoint]
  );

  const metricFilter = filters?.find(
    (filter) =>
      filter.key === "metric" &&
      filter.values.length === 2 &&
      filter.values[1].key === "annual_variation"
  );
  const regionFilter = filters?.find((filter) => filter.key === "region_codes");
  const graphFilters = filters?.filter(
    (filter) =>
      !(
        filter.key === "metric" &&
        filter.values.length === 2 &&
        filter.values[1].key === "annual_variation"
      ) && filter.key !== "region_codes"
  );

  return (
    <>
      <div className="hidden landscape:flex py-4 xl:py-11  flex-1 flex flex-col bg-whitex max-w-96 ps-4 space-y-2">
        <CategoryGraphListPanel
          category={categoryConfig}
          selectedVisualization={visualizationConfig}
        />
        {regionFilter && (
          <RegionFilterSelect
            filter={regionFilter}
            onFilterChange={handleFilterChange} // Este callback se encargará de actualizar la API automáticamente
            visualizationTheme={categoryConfig.theme}
          />
        )}
      </div>
      <div
        ref={bodyContentRef}
        className="relative pt-4 xl:pt-11 pb-32 flex-1 p-4 overflow-auto "
      >
        {isLoading ? (
          <Loader color={categoryConfig.theme.textColor} />
        ) : error ? (
          <div className="inset-0 absolute w-full h-full flex justify-center items-center">
            <div className="p-3 text-2xl text-white border border-white bg-red-500 flex space-x-4 items-center">
              <p>{error}</p>
              <PiSmileySad />
            </div>
          </div>
        ) : (
          // No quiero que se muestre nada de esto si hay un error
          <>
            <div className="max-w-screen-lg mx-auto space-y-6">
              <div className="relative z-10 landscape:hidden flex pt-5 justify-center">
                <VariableSelectMobile
                  filter={{
                    key: "variable",
                    label: "Variable",
                    required: true,
                    type: "string",
                    multiple: false,
                    selected: visualizationConfig.key,
                    values: categoryConfig.visualizations.map((vis) => ({
                      key: vis.key,
                      label: vis.title,
                    })),
                  }}
                  onFilterChange={(key, selected) => {
                    // <Link to={`/analyst/analytics/${category.key}/${visualization.key}`}
                    navigate(
                      `/analyst/analytics/${categoryConfig.key}/${selected}`
                    );
                    console.log("Filter change");
                  }}
                  theme={categoryConfig.theme}
                />
              </div>
              <div className="sm:flex justify-between items-start space-y-4 sm:space-y-0">
                <div className="flex space-x-2">
                  {visualizationConfig && (
                    <ViewModeSwitch
                      viewMode={viewMode}
                      onChange={setViewMode}
                      availableCharts={visualizationConfig.availableCharts}
                      defaultChart={visualizationConfig.defaultChart}
                    />
                  )}

                  {metricFilter && metricFilter.values.length === 2 && (
                    <MetricToggle
                      filter={metricFilter}
                      onFilterChange={handleFilterChange}
                      key="metric-toggle"
                    />
                  )}

                  {isFilterLoading && (
                    <div className="h-8 w-8 relative">
                      <Loader size="1.25rem" color="#000" />
                    </div>
                  )}
                </div>
                <div className="flex flex-wrap justify-start sm:justify-end gap-2">
                  {graphFilters?.map((filter) => (
                    <FilterSelect
                      key={filter.key}
                      filter={filter}
                      onFilterChange={handleFilterChange}
                      theme={categoryConfig.theme}
                    />
                  ))}
                </div>
              </div>
              {/* <div className="bg-black h-[1px] mt-4 mb-12" /> */}
              <div className="graph-container relative">
                {data ? (
                  <ChartRenderer viewMode={viewMode} data={data} />
                ) : null}
              </div>
              <div>
                <DataSource
                  source={visualizationConfig.source}
                  className="mb-4"
                />
                {visualizationConfig.description && (
                  <div className="text-justify p-3">
                    <p className="text-xs text-gray-500">
                      {visualizationConfig.description}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GraphModeViewer;
