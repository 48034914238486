import React from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiOutlineInfoCircle,
  AiOutlineReload,
  AiOutlineWarning,
} from "react-icons/ai";
import formatDate from "src/admin/helpers/formatDate";
import Loader from "src/components/Loader";

// Tipos para las actualizaciones
interface Update {
  date: string;
  description: string;
  type: "success" | "info" | "warning" | "error";
}

interface UpdateHistoryProps {
  updates: Update[] | null;
  fetchLogs: () => void;
}

// Función para obtener el estilo del estado
const getUpdateTypeInfo = (type: "success" | "info" | "warning" | "error") => {
  switch (type) {
    case "success":
      return {
        icon: <AiOutlineCheckCircle size={20} className="text-black" />,
        backgroundClass: "bg-[#33FF00]",
        textClass: "text-black",
      };
    case "error":
      return {
        icon: <AiOutlineExclamationCircle size={20} className="text-black" />,
        backgroundClass: "bg-[#FF6C1A]",
        textClass: "text-black",
      };
    case "warning":
      return {
        icon: <AiOutlineWarning size={20} className="text-black" />,
        backgroundClass: "bg-[#FFFE00]",
        textClass: "text-black",
      };
    case "info":
    default:
      return {
        icon: <AiOutlineInfoCircle size={20} className="text-black" />,
        backgroundClass: "bg-gray-50",
        textClass: "text-black",
      };
  }
};

const UpdateHistory: React.FC<UpdateHistoryProps> = ({
  updates,
  fetchLogs,
}) => {
  return (
    <div className="flex-1 overflow-y-auto p-6 max-h-full relative select-text">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Historial de Actualizaciones</h3>
        <button
          className="text-blue-500 hover:underline flex items-center"
          onClick={fetchLogs}
        >
          <AiOutlineReload className="mr-1" />
          Reiniciar
        </button>
      </div>

      {updates == null ? (
        <Loader color="black" />
      ) : updates.length === 0 ? (
        // Mostrar mensaje cuando no hay actualizaciones
        <div className="flex justify-center items-center h-1/2 text-gray-500">
          No hay registros disponibles
        </div>
      ) : (
        <ul className="space-y-2">
          {updates.map((update, index) => {
            const { icon, backgroundClass, textClass } = getUpdateTypeInfo(
              update.type
            );

            return (
              <li
                key={index}
                className={`flex items-start space-x-3 p-3 border-b border-gray-200 ${backgroundClass} rounded-xl`}
              >
                <div className="flex items-center mt-1.5">{icon}</div>
                <div>
                  <p className={`text-sm ${textClass}`}>{update.description}</p>
                  <p className={`text-xs italic ${textClass}`}>
                    {formatDate(update.date)}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default UpdateHistory;
