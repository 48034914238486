import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Transition } from "@headlessui/react";
import { Img } from "react-image";
import { IoMdClose } from "react-icons/io";
import Loader from "./Loader";
import { Slide } from "src/types/modal.d";

interface WelcomeGuideModalProps {
  slides: Slide[];
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeGuideModal: React.FC<WelcomeGuideModalProps> = ({
  slides = [], // Default value to avoid destructuring issues
  isOpen,
  onClose,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [currentIndex]);

  const nextSlide = () => {
    if (currentIndex < slides.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  if (!slides || slides.length === 0) {
    return null; // If no slides, render nothing
  }

  const currentSlide = slides[currentIndex] || {}; // Safe access to current slide
  const { mediaType, videoSrc, image, mobileImage, title, subtitle } =
    currentSlide;

  const renderMedia = () => {
    if (mediaType === "video" && videoSrc) {
      return (
        <video
          ref={videoRef}
          key={`${videoSrc}?t=${new Date().getTime()}`}
          className="object-cover w-full h-full"
          playsInline
          autoPlay
          loop
          muted
        >
          <source src={videoSrc} type="video/mp4" />
          Tu navegador no soporta el video.
        </video>
      );
    }
    return (
      <>
        {image && (
          <Img
            key={image}
            src={image}
            alt={title}
            className={
              "object-cover object-center w-full h-full" +
              (mobileImage ? " hidden landscape:block" : "")
            }
            loader={<Loader color="black" />}
            unloader={
              <div
                className={
                  "h-full flex items-center justify-center text-gray-500 " +
                  (mobileImage ? " hidden landscape:block" : "")
                }
              >
                Imagen no disponible
              </div>
            }
          />
        )}
        {mobileImage && (
          <Img
            key={mobileImage}
            src={mobileImage}
            alt={title}
            className="object-cover object-center w-full h-full landscape:hidden"
            loader={<Loader color="black" />}
            unloader={
              <div className="h-full flex items-center justify-center text-gray-500 landscape:hidden">
                Imagen no disponible
              </div>
            }
          />
        )}
      </>
    );
  };

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-xl w-full max-w-lg lg:max-w-2xl mx-4 relative border border-white overflow-hidden">
          <div className="w-full aspect-square landscape:aspect-video relative overflow-hidden">
            {renderMedia()}
          </div>

          <button
            className="absolute top-4 right-4 w-8 h-8 rounded-full bg-white text-black shadow-md hover:shadow-lg hover:bg-gray-100 flex items-center justify-center transition-all"
            onClick={onClose}
          >
            <IoMdClose size={20} />
          </button>

          <div className="flex justify-between items-center py-10">
            <button
              onClick={prevSlide}
              className={`sm:p-2 ${currentIndex === 0 ? "opacity-10" : ""}`}
              disabled={currentIndex === 0}
              style={{ cursor: currentIndex === 0 ? "default" : "pointer" }}
            >
              <IoIosArrowBack size={36} />
            </button>

            <div className="text-left mx-3 flex-1 font-sans">
              <h2 className="text-lg sm:text-xl font-semibold mb-1">{title}</h2>
              <p className="text-xs sm:text-sm text-gray-600">{subtitle}</p>
            </div>

            <button
              onClick={nextSlide}
              className={`sm:p-2 ${
                currentIndex === slides.length - 1 ? "opacity-10" : ""
              }`}
              disabled={currentIndex === slides.length - 1}
              style={{
                cursor:
                  currentIndex === slides.length - 1 ? "default" : "pointer",
              }}
            >
              <IoIosArrowForward size={36} />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default WelcomeGuideModal;
