// src/components/YearTimelineControlBar.tsx

import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import * as d3 from "d3";
import { useResizeListener } from "src/hooks/useResizeListener";

interface YearTimelineProps {
  startYear: number;
  endYear: number;
  initialYear?: number;
  onSelect: (year: number) => void;
}

export interface YearTimelineRef {
  setCurrentYear: (year: number) => void;
}

const getBodyScale = () => {
  const bodyMatrix = window.getComputedStyle(document.body).transform;
  return bodyMatrix === "none"
    ? 1
    : parseFloat(bodyMatrix.split(",")[0].split("(")[1]);
};

const YearTimelineControlBar = forwardRef<YearTimelineRef, YearTimelineProps>(
  ({ startYear, endYear, initialYear, onSelect }, ref) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [width, setWidth] = useState(620);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 500);

    // Almacenar currentYear internamente
    const internalYearRef = useRef<number>(initialYear ?? startYear);

    useResizeListener(() => {
      setIsMobile(window.innerWidth < 500);
      setWidth(containerRef.current?.clientWidth || 620);
    });

    // Almacenar las selecciones de D3 en refs
    const progressCircleRef = useRef<d3.Selection<
      SVGCircleElement,
      unknown,
      null,
      undefined
    > | null>(null);

    const currentLabelRef = useRef<d3.Selection<
      SVGTextElement,
      unknown,
      null,
      undefined
    > | null>(null);

    const xScaleRef = useRef<d3.ScaleLinear<number, number>>();

    // Exponer métodos al padre
    useImperativeHandle(ref, () => ({
      setCurrentYear: (year: number) => {
        if (year !== internalYearRef.current) {
          internalYearRef.current = year;

          if (
            xScaleRef.current &&
            progressCircleRef.current &&
            currentLabelRef.current
          ) {
            const targetX = xScaleRef.current(year);

            if (year === startYear) {
              progressCircleRef.current.attr("cx", targetX).interrupt();

              currentLabelRef.current
                .attr("x", targetX)
                .text(year.toString())
                .interrupt();
            } else {
              // Smooth transition for the progress circle
              progressCircleRef.current
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr("cx", targetX);

              // Smooth transition for the current label
              currentLabelRef.current
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr("x", targetX)
                .text(year.toString());
            }
          }
        }
      },
    }));

    useEffect(() => {
      const svg = d3.select(svgRef.current);
      const height = isMobile ? 40 : 60; // Reducir la altura si es móvil
      const margin = isMobile ? 35 : 70; // Reducir margen para pantallas móviles

      // Actualizar el ancho del contenedor dinámicamente
      const containerWidth = containerRef.current?.clientWidth || 620;
      setWidth(containerWidth);

      const xScale = d3
        .scaleLinear()
        .domain([startYear, endYear])
        .range([margin, containerWidth - margin]);

      xScaleRef.current = xScale;

      svg.selectAll("*").remove();

      // Línea principal
      svg
        .append("line")
        .attr("x1", xScale(startYear))
        .attr("x2", xScale(endYear))
        .attr("y1", height / 2)
        .attr("y2", height / 2)
        .attr("stroke", "#000")
        .attr("stroke-width", 1);

      // Marcas de año
      const extendedYears = d3.range(startYear, endYear + 1);
      svg
        .selectAll("circle.year")
        .data(extendedYears)
        .enter()
        .append("circle")
        .attr("class", "year")
        .attr("cx", (d) => xScale(d))
        .attr("cy", height / 2)
        .attr("r", 4)
        .attr("fill", "#999");

      const calculateFontSize = (numElements: number) => {
        const minFontSize = 7; // Tamaño mínimo de fuente para el mayor número de elementos
        const maxFontSize = 12; // Tamaño máximo de fuente para el menor número de elementos
        const minElements = 7; // Número mínimo de elementos
        const maxElements = 19; // Número máximo de elementos

        // Interpolación lineal inversa: más elementos -> tamaño de fuente más pequeño
        return Math.max(
          minFontSize,
          Math.min(
            maxFontSize,
            maxFontSize -
              ((numElements - minElements) / (maxElements - minElements)) *
                (maxFontSize - minFontSize)
          )
        );
      };
      svg
        .selectAll("text.year-label")
        .data(extendedYears)
        .enter()
        .append("text")
        .attr("class", "year-label")
        .attr("x", (d) => xScale(d))
        .attr("y", height / 2 + 20)
        .attr("text-anchor", "middle")
        .text((d) => d.toString())
        // .attr("font-size", "12px") // 10 px if years is more than 10 20
        .attr("font-size", `${calculateFontSize(extendedYears.length)}px`)
        .attr("fill", "#000");

      // Círculo de progreso
      progressCircleRef.current = svg
        .append("circle")
        .attr("cx", xScale(internalYearRef.current))
        .attr("cy", height / 2)
        .attr("r", 8)
        .attr("fill", "#000");

      // Etiqueta actual
      currentLabelRef.current = svg
        .append("text")
        .attr("x", xScale(internalYearRef.current))
        .attr("y", height / 2 - 10)
        .attr("text-anchor", "middle")
        .text(internalYearRef.current.toString())
        .attr("font-size", "14px")
        .attr("fill", "#000")
        .attr("font-weight", "bold");

      svg.on("click", function (event) {
        let [mouseX] = d3.pointer(event);
        mouseX = mouseX / getBodyScale();

        if (mouseX < margin || mouseX > containerWidth - margin) {
          return;
        }

        const closestYear = Math.round(xScale.invert(mouseX));
        const clampedYear = Math.max(startYear, Math.min(closestYear, endYear));

        internalYearRef.current = clampedYear;

        onSelect(clampedYear);

        progressCircleRef.current?.attr("cx", xScale(clampedYear)).interrupt();

        currentLabelRef.current
          ?.attr("x", xScale(clampedYear))
          .text(clampedYear.toString())
          .interrupt();
      });

      const dragBehavior: d3.DragBehavior<SVGSVGElement, unknown, unknown> = d3
        .drag<SVGSVGElement, unknown>()
        .on("drag", function (event) {
          let mouseX;
          if (event.sourceEvent.type === "touchmove") {
            const [internalMouseX] = d3.pointers(event, svgRef.current)[0];
            mouseX = internalMouseX;
          } else {
            const [internalMouseX] = d3.pointer(event, svgRef.current);
            mouseX = internalMouseX;
          }
          if (!isFinite(mouseX)) return;

          mouseX = mouseX / getBodyScale();

          const closestYear = Math.round(xScale.invert(mouseX));
          const clampedYear = Math.max(
            startYear,
            Math.min(closestYear, endYear)
          );

          internalYearRef.current = clampedYear;
          onSelect(clampedYear);

          progressCircleRef.current
            ?.attr("cx", xScale(clampedYear))
            .interrupt();

          currentLabelRef.current
            ?.attr("x", xScale(clampedYear))
            .text(clampedYear.toString())
            .interrupt();
        });

      //   svg.call(dragBehavior);
      (svg as d3.Selection<SVGSVGElement, unknown, null, undefined>).call(
        dragBehavior
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startYear, endYear, onSelect, isMobile, width]);

    useEffect(() => {
      if (
        xScaleRef.current &&
        progressCircleRef.current &&
        currentLabelRef.current
      ) {
        const xScale = xScaleRef.current;

        progressCircleRef.current.attr("cx", xScale(internalYearRef.current));
        currentLabelRef.current
          .attr("x", xScale(internalYearRef.current))
          .text(internalYearRef.current.toString());
      }
    }, [isMobile, width]);

    return (
      <div ref={containerRef} style={{ width: "100%" }}>
        <svg
          ref={svgRef}
          width={width}
          height={isMobile ? 40 : 80}
          className="mx-auto"
        ></svg>
      </div>
    );
  }
);

export default YearTimelineControlBar;
