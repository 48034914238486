// src/config/visualizationService.ts
import { visualizationCategories } from "./visualizationConfig";
import { VisualizationCategory } from "src/types/visualization";

// Convierte un array en un objeto con claves
const arrayToKeyedObject = <T extends { key: string }>(
  array: T[]
): { [key: string]: T } => {
  return array.reduce((acc, item) => {
    acc[item.key] = item;
    return acc;
  }, {} as { [key: string]: T });
};

// Convertir categorías a un objeto con claves
const categoriesByKey = arrayToKeyedObject(visualizationCategories);

// Obtener una categoría por clave
export const getCategoryByKey = (
  categoryKey: string
): VisualizationCategory | null => {
  return categoriesByKey[categoryKey] || null;
};

// Obtener visualizaciones dentro de una categoría por clave
export const getVisualizationByCategoryAndKey = (
  categoryKey: string,
  visualizationKey: string
) => {
  const category = getCategoryByKey(categoryKey);
  if (!category) return null;

  const visualizationsByKey = arrayToKeyedObject(category.visualizations);
  return {
    categoryConfig: category,
    visualizationConfig: visualizationsByKey[visualizationKey] || null,
  };
};

// Obtener la categoría y una visualización específica
export const getCategoryAndVisualization = (
  categoryKey: string,
  visualizationId: string
) => {
  return getVisualizationByCategoryAndKey(categoryKey, visualizationId);
};
