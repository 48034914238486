import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useWelcomeGuideContext } from "src/context/WelcomeGuideContext";

export const useKeydownListener = (
  conditionFn: (key: string, pathname: string, ...args: any[]) => boolean,
  actionFn: () => void,
  ...args: any[]
) => {
  const location = useLocation(); // Obtenemos el pathname actual del hook

  const { isOpen } = useWelcomeGuideContext();

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (isOpen) {
        return;
      }
      const { key } = event;
      const { pathname } = location;

      // Evaluar la condición con la tecla, pathname y otros argumentos
      if (conditionFn(key, pathname, ...args)) {
        actionFn(); // Si la condición se cumple, ejecuta la acción
      }
    };

    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [conditionFn, actionFn, location, args, isOpen]); // Incluir args en las dependencias
};
