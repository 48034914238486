// src/components/TimelineControlBar.tsx

import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import * as d3 from "d3";
import { getShortYearMonth, getLongYearMonth } from "src/utils/timeUtils";
import { useResizeListener } from "src/hooks/useResizeListener";

interface MonthTimelineProps {
  startYear: number;
  endYear: number;
  startMonth: number;
  endMonth: number;
  initialYear?: number;
  initialMonth?: number;
  onSelect: (year: number, month: number) => void;
}

export interface MonthTimelineRef {
  setCurrentDate: (year: number, month: number) => void;
}

const getBodyScale = () => {
  const bodyMatrix = window.getComputedStyle(document.body).transform;
  return bodyMatrix === "none"
    ? 1
    : parseFloat(bodyMatrix.split(",")[0].split("(")[1]);
};
const MonthTimelineControlBar = forwardRef<
  MonthTimelineRef,
  MonthTimelineProps
>(
  (
    {
      startYear,
      endYear,
      startMonth,
      endMonth,
      initialYear,
      initialMonth,
      onSelect,
    },
    ref
  ) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [width, setWidth] = useState(620);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 500);

    // Almacenar currentYear y currentMonth internamente
    const internalYearRef = useRef<number>(initialYear ?? startYear);
    const internalMonthRef = useRef<number>(initialMonth ?? startMonth);

    useResizeListener(() => {
      setIsMobile(window.innerWidth < 500);
      setWidth(containerRef.current?.clientWidth || 620);
    });

    // Almacenar las selecciones de D3 en refs
    const progressCircleRef = useRef<d3.Selection<
      SVGCircleElement,
      unknown,
      null,
      undefined
    > | null>(null);

    const currentLabelRef = useRef<d3.Selection<
      SVGTextElement,
      unknown,
      null,
      undefined
    > | null>(null);

    const hoverLabelRef = useRef<d3.Selection<
      SVGTextElement,
      unknown,
      null,
      undefined
    > | null>(null);
    const xScaleRef = useRef<d3.ScaleLinear<number, number>>();

    // Exponer métodos al padre
    useImperativeHandle(ref, () => ({
      setCurrentDate: (year: number, month: number) => {
        if (
          year !== internalYearRef.current ||
          month !== internalMonthRef.current
        ) {
          internalYearRef.current = year;
          internalMonthRef.current = month;

          if (
            xScaleRef.current &&
            progressCircleRef.current &&
            currentLabelRef.current
          ) {
            const targetX = xScaleRef.current(year + (month - 1) / 12);

            if (year === startYear && month === startMonth) {
              // Si es el primer mes, ir directamente
              progressCircleRef.current.attr("cx", targetX).interrupt();
              currentLabelRef.current
                .attr("x", targetX)
                .text(
                  isMobile
                    ? getShortYearMonth(year, month) // Usar versión corta para pantallas pequeñas
                    : getLongYearMonth(year, month) // Usar versión larga para pantallas grandes
                )
                .interrupt();
            } else {
              // Smooth transition for the progress circle
              progressCircleRef.current
                .transition()
                .duration(500)
                .ease(d3.easeLinear)
                .attr("cx", targetX);

              // Smooth transition for the current label
              currentLabelRef.current
                .transition()
                .duration(500)
                .ease(d3.easeLinear)
                .attr("x", targetX)
                .text(
                  isMobile
                    ? getShortYearMonth(year, month) // Usar versión corta para pantallas pequeñas
                    : getLongYearMonth(year, month) // Usar versión larga para pantallas grandes
                );
            }
          }
        }
      },
    }));

    useEffect(() => {
      const svg = d3.select(svgRef.current);
      const height = isMobile ? 40 : 60; // Reducir la altura si es móvil

      // Reducir margen para pantallas móviles
      const margin = isMobile ? 35 : 70;

      // Actualizar el ancho del contenedor dinámicamente
      const containerWidth = containerRef.current?.clientWidth || 620;
      setWidth(containerWidth);

      const monthsPerYear: { [year: number]: number } = {};
      for (let year = startYear; year <= endYear; year++) {
        monthsPerYear[year] =
          year === startYear
            ? 12 - startMonth + 1
            : year === endYear
            ? endMonth
            : 12;
      }

      const xScale = d3
        .scaleLinear()
        .domain([
          startYear + (startMonth - 1) / 12,
          endYear + (endMonth - 1) / 12,
        ])
        .range([margin, containerWidth - margin]);

      xScaleRef.current = xScale;

      svg.selectAll("*").remove();

      // Línea principal
      svg
        .append("line")
        .attr("x1", xScale(startYear + (startMonth - 1) / 12))
        .attr("x2", xScale(endYear + (endMonth - 1) / 12))
        .attr("y1", height / 2)
        .attr("y2", height / 2)
        .attr("stroke", "#000")
        .attr("stroke-width", 1);

      // Marcas de año
      const extendedYears = [...d3.range(startYear + 1, endYear), endYear];
      svg
        .selectAll("circle.year")
        .data(extendedYears)
        .enter()
        .append("circle")
        .attr("class", "year")
        .attr("cx", (d) => xScale(d))
        .attr("cy", height / 2)
        .attr("r", 4)
        .attr("fill", "#999");

      svg
        .selectAll("text.year-label")
        .data(extendedYears)
        .enter()
        .append("text")
        .attr("class", "year-label")
        .attr("x", (d) => xScale(d))
        .attr("y", height / 2 + 20)
        .attr("text-anchor", "middle")
        .text((d) => (d <= endYear ? d.toString() : ""))
        .attr("font-size", "12px")
        .attr("fill", "#000");

      // Círculos de inicio y fin
      svg
        .append("circle")
        .attr("cx", xScale(startYear + (startMonth - 1) / 12))
        .attr("cy", height / 2)
        .attr("r", 6)
        .attr("fill", "#999");

      svg
        .append("circle")
        .attr("cx", xScale(endYear + (endMonth - 1) / 12))
        .attr("cy", height / 2)
        .attr("r", 6)
        .attr("fill", "#999");

      // Círculo de progreso
      progressCircleRef.current = svg
        .append("circle")
        .attr(
          "cx",
          xScale(internalYearRef.current + (internalMonthRef.current - 1) / 12)
        )
        .attr("cy", height / 2)
        .attr("r", 8)
        .attr("fill", "#000");

      // Etiqueta actual
      currentLabelRef.current = svg
        .append("text")
        .attr(
          "x",
          xScale(internalYearRef.current + (internalMonthRef.current - 1) / 12)
        )
        .attr("y", height / 2 - 10)
        .attr("text-anchor", "middle")
        .text(
          isMobile
            ? getShortYearMonth(
                internalYearRef.current,
                internalMonthRef.current
              ) // Versión corta
            : getLongYearMonth(
                internalYearRef.current,
                internalMonthRef.current
              ) // Versión larga
        )
        .attr("font-size", "14px")
        .attr("fill", "#000")
        .attr("font-weight", "bold");

      // Etiqueta de hover (solo para dispositivos no móviles)
      if (!isMobile) {
        hoverLabelRef.current = svg
          .append("text")
          .attr("x", containerWidth / 2)
          .attr("y", height / 2 + 40)
          .attr("text-anchor", "middle")
          .attr("font-size", "12px")
          .attr("fill", "#999")
          .attr("visibility", "hidden");
      }

      const flexMargin = 5;

      // Eventos solo para dispositivos no móviles
      if (!isMobile) {
        svg.on("mousemove", function (event) {
          const [mouseX] = d3.pointer(event);
          const closestYear = Math.floor(xScale.invert(mouseX));

          if (
            mouseX < margin - flexMargin ||
            mouseX > containerWidth - margin + flexMargin
          ) {
            hoverLabelRef.current?.attr("visibility", "hidden");
            return;
          }

          const clampedYear = Math.max(
            startYear,
            Math.min(closestYear, endYear)
          );
          const yearStartX = xScale(clampedYear);
          const yearEndX = xScale(clampedYear + 1);
          const monthWidth = (yearEndX - yearStartX) / 12;

          const relativeX = mouseX - yearStartX;
          const hoveredMonth = Math.min(
            Math.max(
              Math.ceil(relativeX / monthWidth),
              clampedYear === startYear ? startMonth : 1
            ),
            clampedYear !== endYear ? 12 : monthsPerYear[clampedYear]
          );

          hoverLabelRef.current
            ?.attr("x", mouseX)
            .attr("visibility", "visible")
            .text(getShortYearMonth(clampedYear, hoveredMonth)); // Usar la versión corta
        });

        svg.on("mouseout", function () {
          hoverLabelRef.current?.attr("visibility", "hidden");
        });
      }

      svg.on("click", function (event) {
        let [mouseX] = d3.pointer(event);

        mouseX = mouseX / getBodyScale();
        if (
          mouseX < margin - flexMargin ||
          mouseX > containerWidth - margin + flexMargin
        ) {
          return;
        }

        const closestYear = Math.floor(xScale.invert(mouseX));
        const clampedYear = Math.max(startYear, Math.min(closestYear, endYear));
        const yearStartX = xScale(clampedYear);
        const yearEndX = xScale(clampedYear + 1);
        const monthWidth = (yearEndX - yearStartX) / 12;

        const relativeX = mouseX - yearStartX;
        const hoveredMonth = Math.min(
          Math.max(
            Math.ceil(relativeX / monthWidth),
            clampedYear === startYear ? startMonth : 1
          ),
          clampedYear !== endYear ? 12 : monthsPerYear[clampedYear]
        );

        internalYearRef.current = clampedYear;
        internalMonthRef.current = hoveredMonth;

        onSelect(clampedYear, hoveredMonth);

        progressCircleRef.current
          ?.attr("cx", xScale(clampedYear + (hoveredMonth - 1) / 12))
          .interrupt();

        currentLabelRef.current
          ?.attr("x", xScale(clampedYear + (hoveredMonth - 1) / 12))
          .text(
            isMobile
              ? getShortYearMonth(clampedYear, hoveredMonth) // Versión corta
              : getLongYearMonth(clampedYear, hoveredMonth) // Versión larga
          )
          .interrupt();
      });

      const dragBehavior: d3.DragBehavior<SVGSVGElement, unknown, unknown> = d3
        .drag<SVGSVGElement, unknown>()
        .on("drag", function (event) {
          let mouseX;
          if (event.sourceEvent.type === "touchmove") {
            const [internalMouseX] = d3.pointers(event, svgRef.current)[0];
            mouseX = internalMouseX;
          } else {
            const [internalMouseX] = d3.pointer(event, svgRef.current);
            mouseX = internalMouseX;
          }

          if (!isFinite(mouseX)) return; // Manejar el valor no finito

          mouseX = mouseX / getBodyScale();
          const closestYear = Math.floor(xScale.invert(mouseX));

          const clampedYear = Math.max(
            startYear,
            Math.min(closestYear, endYear)
          );
          const yearStartX = xScale(clampedYear);
          const yearEndX = xScale(clampedYear + 1);
          const monthWidth = (yearEndX - yearStartX) / 12;
          const relativeX = mouseX - yearStartX;

          const selectedMonth = Math.min(
            Math.max(
              Math.ceil(relativeX / monthWidth),
              clampedYear === startYear ? startMonth : 1
            ),
            clampedYear !== endYear ? 12 : monthsPerYear[clampedYear]
          );

          internalYearRef.current = clampedYear;
          internalMonthRef.current = selectedMonth;

          onSelect(clampedYear, selectedMonth);

          progressCircleRef.current
            ?.attr("cx", xScale(clampedYear + (selectedMonth - 1) / 12))
            .interrupt();
          currentLabelRef.current
            ?.attr("x", xScale(clampedYear + (selectedMonth - 1) / 12))
            .text(
              isMobile
                ? getShortYearMonth(clampedYear, selectedMonth) // Versión corta
                : getLongYearMonth(clampedYear, selectedMonth) // Versión larga
            )
            .interrupt();
        });

      (svg as d3.Selection<SVGSVGElement, unknown, null, undefined>).call(
        dragBehavior
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startYear, endYear, startMonth, endMonth, onSelect, isMobile, width]);

    useEffect(() => {
      if (
        xScaleRef.current &&
        progressCircleRef.current &&
        currentLabelRef.current
      ) {
        const xScale = xScaleRef.current;

        progressCircleRef.current.attr(
          "cx",
          xScale(internalYearRef.current + (internalMonthRef.current - 1) / 12)
        );
        currentLabelRef.current
          .attr(
            "x",
            xScale(
              internalYearRef.current + (internalMonthRef.current - 1) / 12
            )
          )
          .text(
            isMobile
              ? getShortYearMonth(
                  internalYearRef.current,
                  internalMonthRef.current
                ) // Versión corta
              : getLongYearMonth(
                  internalYearRef.current,
                  internalMonthRef.current
                ) // Versión larga
          );
      }
    }, [isMobile, width]);

    return (
      <div ref={containerRef} style={{ width: "100%" }}>
        <svg
          ref={svgRef}
          width={width}
          height={isMobile ? 40 : 80}
          className="mx-auto"
        ></svg>
      </div>
    );
  }
);

export default MonthTimelineControlBar;
