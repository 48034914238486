import { useState } from "react";
import axios from "axios";
import { Resource } from "../../../types/Resource";

const useResourceApi = () => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<
    | {
        date: string;
        description: string;
        type: "success" | "info" | "warning" | "error";
      }[]
    | null
  >(null);

  // Helper function to reset messages
  const resetMessages = () => {
    setError(null);
    setSuccessMessage(null);
  };

  const fetchResources = async () => {
    resetMessages();
    setIsLoading(true);
    try {
      const response = await axios.get<Resource[]>("/api/admin/resources");
      setResources(response.data);
      setSuccessMessage("Recursos cargados correctamente.");
    } catch (err) {
      setError("Error al cargar los recursos");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLogs = async (resourceKey: string) => {
    setIsLoading(true);
    try {
      setLogs(null); // Limpiar los logs antes de cargar
      const response = await axios.get(
        `/api/admin/resources/${resourceKey}/logs`
      );
      const fetchedLogs = response.data.map((log: any) => ({
        date: log.created_at,
        description: log.message,
        type: log.event_type as "success" | "info" | "warning" | "error",
      }));
      setLogs(fetchedLogs);
    } catch (error) {
      setError("Error al obtener los logs");
    } finally {
      setIsLoading(false);
    }
  };

  const createResource = async (resource: Partial<Resource>) => {
    resetMessages();
    setIsLoading(true);
    try {
      await axios.post("/api/admin/resources", resource);
      await fetchResources(); // Refrescar la lista después de crear
      setSuccessMessage("Recurso creado correctamente.");
    } catch (err) {
      setError("Error al crear el recurso");
    } finally {
      setIsLoading(false);
    }
  };

  const updateResource = async (id: number, resource: Partial<Resource>) => {
    resetMessages();
    setIsLoading(true);
    try {
      await axios.put(`/api/admin/resources/${id}`, resource);
      await fetchResources(); // Refrescar la lista después de actualizar
      setSuccessMessage("Recurso actualizado correctamente.");
    } catch (err) {
      setError("Error al actualizar el recurso");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteResource = async (id: number) => {
    resetMessages();
    setIsLoading(true);
    try {
      await axios.delete(`/api/admin/resources/${id}`);
      await fetchResources(); // Refrescar la lista después de eliminar
      setSuccessMessage("Recurso eliminado correctamente.");
    } catch (err) {
      setError("Error al eliminar el recurso");
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFile = async (file: File, resourceKey: string) => {
    resetMessages();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(`/api/admin/resources/${resourceKey}/upload`, formData);
      setSuccessMessage("Archivo subido correctamente.");
    } catch (err) {
      setError("Error al subir el archivo");
    } finally {
      setIsLoading(false);
    }
  };

  const downloadFile = async (resourceKey: string, resourceName: string) => {
    setIsLoading(true);
    try {
      // Solicita el enlace de descarga desde el backend
      const response = await axios.post(
        `/api/admin/resources/${resourceKey}/download-link`
      );

      const { download_url, file_name } = response.data;
      if (!download_url || !file_name) {
        throw new Error("Enlace de descarga o nombre de archivo no disponible");
      }

      // Reemplaza la primera parte del nombre del archivo con resourceName
      const fileNameParts = file_name.split("-");
      fileNameParts[0] = resourceName;
      const adjustedFileName = fileNameParts.join(" - ");

      // Crea un enlace <a> y simula un clic para descargar el archivo
      const link = document.createElement("a");
      link.href = download_url;
      link.setAttribute("download", adjustedFileName); // Usa el nombre ajustado del archivo
      document.body.appendChild(link);
      link.click();
      link.remove();

      setSuccessMessage("Descarga iniciada correctamente.");
    } catch (err) {
      console.error("Error al descargar el archivo:", err);
      setError("Error al iniciar la descarga");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    resources,
    logs,
    error,
    successMessage,
    isLoading,
    fetchResources,
    fetchLogs,
    createResource,
    updateResource,
    deleteResource,
    uploadFile,
    downloadFile, // Agregamos el método de descarga
  };
};

export default useResourceApi;
