export const monthsInSpanish = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const shortMonthsInSpanish = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

/**
 * Devuelve el formato del mes y año en formato "mes del año".
 * @param month El número del mes (1-12).
 * @param year El número del año.
 * @param isShort Si es true, devuelve la versión corta del mes (3 letras).
 * @returns El formato "mes del año".
 */
const formatMonthYear = (
  year: number,
  month: number,
  isShort = false
): string => {
  const monthName = isShort
    ? shortMonthsInSpanish[month - 1]
    : monthsInSpanish[month - 1];

  return `${monthName} ${year}`;
};

// Para la versión corta
export const getShortYearMonth = (year: number, month: number) =>
  formatMonthYear(year, month, true); // Ejemplo: "Ene 2024"

// Para la versión larga
export const getLongYearMonth = (year: number, month: number) =>
  formatMonthYear(year, month); // Ejemplo: "Enero 2024"

export const formatForRacingBars = (
  year: number,
  month?: number,
  day?: number
) => {
  if (month && day) {
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  } else if (month) {
    return `${year}-${month.toString().padStart(2, "0")}-16`;
  } else {
    return `${year}-12-31`;
  }
};
