// admin/modules/history/pages/ProcessedResourcesPage.tsx
import React, { useEffect } from "react";
import AdminLayout from "src/admin/layout/AdminLayout";
import useProcessedResourceApi from "../hooks/useProcessedResourceApi";
import { FiDownload } from "react-icons/fi";
import axios from "axios";
import { ProcessedResource } from "../hooks/useProcessedResourceApi";
import Loader from "src/components/Loader";

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

// Helper function to group and sort resources by day
const groupResourcesByDay = (
  resources: ProcessedResource[]
): [string, ProcessedResource[]][] => {
  // Sort resources from most recent to oldest
  resources.sort(
    (a, b) =>
      new Date(b.processed_at).getTime() - new Date(a.processed_at).getTime()
  );

  const grouped = resources.reduce(
    (acc: { [key: string]: ProcessedResource[] }, resource) => {
      const date = capitalizeFirstLetter(
        new Date(resource.processed_at).toLocaleDateString("es-CL", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(resource);
      return acc;
    },
    {}
  );

  return Object.entries(grouped);
};

const ProcessedResourcesPage: React.FC = () => {
  const { processedResources, error, isLoading, fetchProcessedResources } =
    useProcessedResourceApi();

  useEffect(() => {
    fetchProcessedResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = async (resourceKey: string, fileName: string) => {
    try {
      const response = await axios.post(
        `/api/admin/resources/${resourceKey}/download-link`,
        { file_name: fileName }
      );
      const { download_url } = response.data;

      // Crea un enlace <a> y simula un clic para descargar el archivo
      const link = document.createElement("a");
      link.href = download_url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
      alert("Error al descargar el archivo. Inténtalo de nuevo más tarde.");
    }
  };

  const groupedResources = groupResourcesByDay(processedResources);

  return (
    <AdminLayout
      header="Recursos Procesados"
      message="Aquí puedes ver el historial de archivos procesados y detalles sobre cada uno, agrupados por día."
    >
      {isLoading && <Loader color="black"></Loader>}
      {error && <p className="text-center text-red-500 mb-4">{error}</p>}

      <div className="max-w-screen-md mx-auto space-y-6">
        {groupedResources.length > 0
          ? groupedResources.map(([date, resources], index) => (
              <div key={index} className="space-y-4">
                <h2 className="text-xl font-bold text-gray-800">{date}</h2>
                <ul className="space-y-2">
                  {resources.map((resource, idx) => (
                    <li
                      key={idx}
                      className="flex items-center justify-between border border-gray-200 p-4 rounded-lg shadow-sm bg-white hover:bg-gray-50 transition-colors"
                    >
                      <div className="flex flex-col">
                        <h3 className="text-lg  font- text-gray-800">
                          {resource.resource_name}
                        </h3>
                      </div>
                      <div className="flex items-center space-x-4">
                        <span className="text-sm text-gray-500">
                          {new Date(resource.processed_at)
                            .toLocaleTimeString("es-CL", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                            .toLowerCase()}
                        </span>
                        <button
                          className="text-gray-500 hover:text-gray-800"
                          onClick={() =>
                            handleDownload(
                              resource.resource_key,
                              resource.file_name
                            )
                          }
                          aria-label="Descargar"
                        >
                          <FiDownload size={24} />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))
          : !isLoading && (
              <p className="text-center">No hay recursos procesados.</p>
            )}
      </div>
    </AdminLayout>
  );
};

export default ProcessedResourcesPage;
