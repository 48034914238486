import { useEffect } from "react";
import debounce from "lodash.debounce"; // Importamos debounce

export const useResizeListener = (
  resizeHandler: () => void,
  delay: number = 0
) => {
  useEffect(() => {
    // Aplicamos debounce a la función resizeHandler para que no se ejecute tantas veces
    const debouncedResizeHandler = debounce(resizeHandler, delay);

    const handleResize = () => {
      debouncedResizeHandler();
    };

    handleResize(); // Llamar inicialmente para evaluar el estado actual

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      debouncedResizeHandler.cancel(); // Cancelar el debounce al desmontar el componente
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);
};
