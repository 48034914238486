import React, { useState, useEffect } from "react";
import { Resource, ResourceAccessLabels } from "../../../types/Resource";
import {
  AiOutlineCloudUpload,
  AiOutlineLink,
  AiOutlineSearch,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import Select from "react-select";

interface ResourceFormProps {
  resource: Partial<Resource>;
  onChange: (updatedResource: Partial<Resource>) => void;
  onSave: () => void;
  onCancel: () => void;
  isEditing: boolean;
  userRole: "admin-full" | "admin-limited";
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
}

const options = [
  // Opciones para el selector de tipo de acceso
  {
    value: "manual-input",
    label: (
      <div className="flex items-center space-x-2">
        <AiOutlineCloudUpload className="text-blue-500" />
        <span>{ResourceAccessLabels["manual-input"].description}</span>
      </div>
    ),
  },
  {
    value: "direct-link",
    label: (
      <div className="flex items-center space-x-2">
        <AiOutlineLink className="text-green-500" />
        <span>{ResourceAccessLabels["direct-link"].description}</span>
      </div>
    ),
  },
  {
    value: "site-link-search",
    label: (
      <div className="flex items-center space-x-2">
        <AiOutlineSearch className="text-yellow-500" />
        <span>{ResourceAccessLabels["site-link-search"].description}</span>
      </div>
    ),
  },
  {
    value: "site-row-search",
    label: (
      <div className="flex items-center space-x-2">
        <AiOutlineSearch className="text-yellow-500" />
        <span>{ResourceAccessLabels["site-row-search"].description}</span>
      </div>
    ),
  },
];

const ResourceForm: React.FC<ResourceFormProps> = ({
  resource,
  onChange,
  onSave,
  onCancel,
  isEditing,
  userRole,
  selectedFile,
  setSelectedFile,
}) => {
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  // Función para manejar la cancelación
  const handleCancel = () => {
    setSelectedFile(null);
    onCancel();
  };

  const handleChange = (field: keyof Resource, value: any) => {
    onChange({ ...resource, [field]: value });
  };

  const handleFileChange = (file: File) => {
    const allowedExtensions = [".xlsx", ".csv"];
    const fileExtension = "." + file.name.split(".").pop()?.toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      setUploadError(
        "Formato de archivo incompatible. Solo se permiten .xlsx y .csv"
      );
      return;
    }

    setSelectedFile(file);
    setUploadError(null); // Limpiar el error si el archivo es válido
  };

  // Manejadores de Drag & Drop
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  // Limpia el archivo seleccionado cuando el componente se desmonta
  useEffect(() => {
    return () => {
      setSelectedFile(null);
    };
  }, [setSelectedFile]);

  return (
    <div className="resource-form max-w-screen-sm py-5 mx-auto space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Nombre
        </label>
        <input
          type="text"
          value={resource.name || ""}
          onChange={(e) => handleChange("name", e.target.value)}
          className="mt-1 p-2 border rounded w-full"
        />
      </div>

      {userRole === "admin-full" && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Clave del Recurso
          </label>
          <input
            type="text"
            value={resource.resource_key || ""}
            onChange={(e) => handleChange("resource_key", e.target.value)}
            className="mt-1 p-2 border rounded w-full italic"
          />
        </div>
      )}
      <hr className="border-gray-300 my-4" />

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Tipo de Acceso
        </label>
        <Select
          value={options.find(
            (option) => option.value === resource.access_type
          )}
          onChange={(option) =>
            handleChange("access_type", option ? option.value : "")
          }
          options={options}
          className="mt-1 mb-2"
          classNamePrefix="react-select"
          placeholder="Selecciona un tipo de acceso"
          isClearable={false}
        />
      </div>

      {resource.access_type === "manual-input" && (
        <div
          className={`mt-4 border-dashed border-2 rounded-lg p-6 text-center transition ${
            isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
          } ${selectedFile ? "border-green-500 bg-green-50" : ""}`}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {selectedFile ? (
            <div className="flex flex-col items-center text-green-700">
              <AiOutlineCheckCircle size={40} className="mb-2" />
              <p className="text-sm font-semibold">{selectedFile.name}</p>
            </div>
          ) : (
            <>
              <p className="text-sm text-gray-500 mb-2">
                Arrastra y suelta un archivo o haz clic para subir
              </p>
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    handleFileChange(e.target.files[0]);
                  }
                }}
                className="hidden"
                id="file-upload"
              />
              <label
                htmlFor="file-upload"
                className="cursor-pointer flex flex-col items-center"
              >
                <AiOutlineCloudUpload
                  size={40}
                  className="text-blue-500 mb-2"
                />
                <p className="text-sm text-gray-500">Subir archivo aquí</p>
              </label>
            </>
          )}
          {uploadError && (
            <p className="text-red-500 text-sm mt-2">{uploadError}</p>
          )}
        </div>
      )}

      {resource.access_type === "direct-link" && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            URL del Enlace de Descarga
          </label>
          <input
            type="text"
            value={resource.url || ""}
            onChange={(e) => handleChange("url", e.target.value)}
            className="mt-1 p-2 border rounded w-full"
          />
        </div>
      )}

      {(resource.access_type === "site-link-search" ||
        resource.access_type === "site-row-search") && (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              URL para el Scraping
            </label>
            <input
              type="text"
              value={resource.url || ""}
              onChange={(e) => handleChange("url", e.target.value)}
              className="mt-1 p-2 border rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Término de Búsqueda
            </label>
            <input
              type="text"
              value={resource.search_term || ""}
              onChange={(e) => handleChange("search_term", e.target.value)}
              className="mt-1 p-2 border rounded w-full"
            />
          </div>
        </>
      )}

      <div className="flex justify-start space-x-4 pt-8">
        <button
          onClick={handleCancel}
          className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
        >
          Cancelar
        </button>
        <button
          onClick={onSave}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          {isEditing ? "Guardar Cambios" : "Crear Recurso"}
        </button>
      </div>
    </div>
  );
};

export default ResourceForm;
