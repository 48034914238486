import React, { useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiHome, FiBell, FiLogOut } from "react-icons/fi";
import gsap from "gsap"; // Importar GSAP

const AdminMenu: React.FC = () => {
  const barRef = useRef<HTMLDivElement>(null); // Referencia para la barra
  const location = useLocation();
  const navigate = useNavigate();

  // Estado para simular si hay notificaciones
  const hasNotifications = true; // Cambia a "true" o "false" según si hay notificaciones

  // Función para verificar si la ruta está activa
  const isActive = (path: string) => location.pathname === path;

  // Función para cerrar sesión
  const handleLogout = () => {
    // Eliminar todos los datos de la sesión
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiration");

    // Puedes agregar más datos a eliminar si los tienes en localStorage o sessionStorage
    // Redirigir al usuario a la página de inicio de sesión
    navigate("/login");
  };

  // Animación de entrada con GSAP
  useEffect(() => {
    const buttons = barRef.current?.querySelectorAll("button"); // Seleccionar todos los botones
    if (!barRef.current || !buttons) return;

    // Eliminar cualquier animación previa para evitar solapamientos
    gsap.killTweensOf(barRef.current);
    gsap.killTweensOf(buttons);

    // Animación de entrada de la barra
    gsap.set(barRef.current, {
      opacity: 0,
      scaleY: 0,
      scaleX: 0.9,
    });

    gsap.set(buttons, {
      opacity: 0,
      scale: 0,
      y: 60,
      filter: () => "blur(" + Math.random() * 12 + "px)",
    });

    gsap.delayedCall(0.25, () => {
      gsap.to(barRef.current, {
        opacity: 1,
        scaleY: 1,
        scaleX: 1,
        duration: 0.5,
        ease: "ease.out",
        filter: "blur(0px)",
      });

      gsap.delayedCall(0, () => {
        buttons.forEach((button, index) => {
          gsap.to(button, {
            opacity: 1,
            scale: 1,
            filter: "blur(0px)",
            y: 0,
            duration: 0.5,
            ease: "ease.out",
            delay: 0.1 * index,
          });
        });
      });
    });
  }, []);

  return (
    <div
      ref={barRef} // Referencia a la barra para GSAP
      className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white/10 backdrop-blur-md border border-[#5D5D5D] rounded-full shadow-lg flex space-x-5 px-8 py-2.5"
    >
      {/* Botón para ir al panel de administrador */}
      <Link to="/admin">
        <button
          className={`p-2.5 rounded-full border ${
            isActive("/admin") ? "bg-black text-white" : "bg-white text-black"
          }`}
        >
          <FiHome size={20} />
        </button>
      </Link>

      {/* Botón para ir a "Notifications" */}
      <Link to="/admin/notifications">
        <div className="relative">
          <button
            className={`p-2.5 rounded-full border ${
              isActive("/admin/notifications")
                ? "bg-black text-white"
                : "bg-white text-black"
            }`}
          >
            <FiBell size={20} />
            {hasNotifications && (
              <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full border border-white"></span>
            )}
          </button>
          {/* Punto rojo de notificación */}
        </div>
      </Link>

      {/* Botón para cerrar sesión */}
      <button
        className="p-2.5 rounded-full border bg-red-500 text-white hover:bg-red-700"
        onClick={handleLogout}
      >
        <FiLogOut size={20} />
      </button>
    </div>
  );
};

export default AdminMenu;
