import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { takeScreenshotAndDownload } from "src/utils/screenshot";
import gsap from "gsap";

// Importación de íconos
import { GoHistory } from "react-icons/go";
import { MdAutoGraph } from "react-icons/md";
import { TfiDownload } from "react-icons/tfi";
import { FiBarChart2 } from "react-icons/fi";
import { IoMdHelp } from "react-icons/io";
import { RxReader } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";

import { useReadingMode } from "src/context/ReadingModeContext";
import { useKeydownListener } from "src/hooks/useKeydownListener";
import { useWelcomeGuideContext } from "src/context/WelcomeGuideContext";

const LAST_ANALYTICS_ROUTE_KEY = "lastAnalyticsRoute";

const ActionMenu: React.FC = () => {
  const { isReadingMode, toggleReadingMode } = useReadingMode();
  const { hasGuide, currentSlides, openWelcomeGuide } =
    useWelcomeGuideContext();
  const location = useLocation();
  const navigate = useNavigate();

  const barRef = useRef<HTMLDivElement>(null);

  // Array de referencias para botones y enlaces
  const elementRefs = useRef<(HTMLButtonElement | HTMLAnchorElement)[]>([]);
  const [lastAnalyticsRoute, setLastAnalyticsRoute] = useState<string | null>(
    localStorage.getItem(LAST_ANALYTICS_ROUTE_KEY)
  );

  const routeConfigs = {
    downloadButton: [
      "/analyst/timeline/",
      "/analyst/predict",
      "/analyst/analytics",
    ],
    readingMode: ["/analyst/analytics"],
    closeButton: [
      "/analyst/timeline",
      "/analyst/predict",
      "/analyst/analytics",
    ],
  };

  useEffect(() => {
    if (location.pathname.startsWith("/analyst/analytics")) {
      localStorage.setItem(LAST_ANALYTICS_ROUTE_KEY, location.pathname);
      setLastAnalyticsRoute(location.pathname);
    }
  }, [location.pathname]);

  const isActive = (path: string) => location.pathname.startsWith(path);
  const showButton = (routes: string[]) =>
    routes.some((route) => isActive(route));

  useEffect(() => {
    if (!barRef.current || !elementRefs.current.length) {
      return;
    }
    gsap.killTweensOf([barRef.current, ...elementRefs.current]);

    gsap.set(barRef.current, { opacity: 0, scaleY: 0, scaleX: 0.9 });

    elementRefs.current.forEach((element) => {
      gsap.set(element, {
        opacity: 0,
        scale: 0,
        y: 60,
        filter: "blur(12px)",
      });
    });

    gsap.delayedCall(0.25, () => {
      gsap.to(barRef.current, {
        opacity: 1,
        scaleY: 1,
        scaleX: 1,
        duration: 0.5,
        ease: "ease.out",
      });

      elementRefs.current.forEach((element, index) => {
        gsap.to(element, {
          opacity: 1,
          scale: 1,
          y: 0,
          filter: "blur(0px)",
          delay: 0.1 * index,
          duration: 0.5,
          ease: "ease.out",
        });
      });
    });
  }, []);

  useKeydownListener(
    (key) => key === "Escape",
    () => navigate("/analyst"),
    showButton(routeConfigs.closeButton)
  );

  // Componente de Enlace con referencia
  const ActionLink = ({
    to,
    icon: Icon,
    tooltip,
    index,
    active,
  }: {
    to: string;
    icon: React.ElementType;
    tooltip: string;
    index: number;
    active?: boolean;
  }) => (
    <Link
      ref={(el) => (elementRefs.current[index] = el!)}
      to={to}
      className={`p-2.5 rounded-full border ${
        active ? "bg-black text-white" : "bg-white text-black"
      }`}
      data-tooltip-id="tooltip"
      data-tooltip-content={tooltip}
    >
      <Icon size={20} />
    </Link>
  );

  // Componente de Botón con referencia
  const ActionButton = ({
    icon: Icon,
    tooltip,
    onClick,
    index,
    active,
  }: {
    icon: React.ElementType;
    tooltip: string;
    onClick: () => void;
    index: number;
    active?: boolean;
  }) => (
    <button
      ref={(el) => (elementRefs.current[index] = el!)}
      className={`p-2.5 rounded-full border ${
        active ? "bg-black text-white" : "bg-white text-black"
      }`}
      onClick={onClick}
      data-tooltip-id="tooltip"
      data-tooltip-content={tooltip}
    >
      <Icon size={20} />
    </button>
  );

  return (
    <div className="hidden-for-screenshot pointer-events-none fixed bottom-0 sm:bottom-9 left-0 z-20 w-full h-auto flex justify-center">
      <div
        ref={barRef}
        className="w-full sm:w-fit opacity-0 pointer-events-auto flex justify-around sm:justify-center space-x-5 px-8 py-2.5 bg-white/10 backdrop-blur-md border-t border-x sm:border border-[#5D5D5D] rounded-t-3xl sm:rounded-full shadow-lg"
      >
        <ActionLink
          to="/analyst/timeline"
          icon={GoHistory}
          tooltip="Linea de tiempo"
          active={isActive("/analyst/timeline")}
          index={0}
        />
        <ActionLink
          to="/analyst/predict"
          icon={MdAutoGraph}
          tooltip="Análisis Avanzado"
          active={isActive("/analyst/predict")}
          index={1}
        />

        {showButton(routeConfigs.readingMode) ? (
          <ActionButton
            icon={isReadingMode ? RxReader : FiBarChart2}
            tooltip={
              isReadingMode
                ? "Cambiar al modo gráfico"
                : "Cambiar al modo lector"
            }
            onClick={toggleReadingMode}
            active={true}
            index={2}
          />
        ) : (
          <ActionLink
            to={
              lastAnalyticsRoute ||
              "/analyst/analytics/territorial_statistics/attractions"
            }
            icon={isReadingMode ? RxReader : FiBarChart2}
            tooltip="Análisis de Datos"
            active={isActive("/analyst/analytics")}
            index={2}
          />
        )}

        {(showButton(routeConfigs.downloadButton) || hasGuide) && (
          <div className="min-w-[1px] bg-[#5D5D5D]"></div>
        )}

        {showButton(routeConfigs.downloadButton) && (
          <ActionButton
            icon={TfiDownload}
            tooltip="Descargar en PDF"
            onClick={takeScreenshotAndDownload}
            index={3}
          />
        )}

        {hasGuide && (
          <ActionButton
            icon={IoMdHelp}
            tooltip="Guía de bienvenida"
            onClick={() => openWelcomeGuide(currentSlides)}
            index={4}
          />
        )}

        {showButton(routeConfigs.closeButton) && (
          <>
            <div className="min-w-[1px] bg-[#5D5D5D]"></div>
            <ActionButton
              icon={AiOutlineClose}
              tooltip="Cerrar"
              onClick={() => navigate("/analyst")}
              index={5}
              active={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ActionMenu;
