import React, { useCallback, useRef } from "react";
import { IoIosClose } from "react-icons/io";
import { GoPlusCircle } from "react-icons/go";
import Select from "react-select";
import { AvailableFilter } from "src/types/graph";
import { useGeographicSelection } from "src/context/GeographicSelectionContext";
import { VisualizationTheme } from "src/types/visualization";
import useScrollMask from "src/hooks/useScrollMask";
import { regionsConfig } from "src/config/regionsConfig";

interface RegionFilterSelectProps {
  filter: AvailableFilter; // Filtro específico para `region_codes`
  onFilterChange: (
    filterKey: string,
    selected: string | number | Array<string | number> | null
  ) => void; // Callback para manejar el cambio en los filtros
  visualizationTheme?: VisualizationTheme; // Tema de la visualización
  readerMode?: boolean; // Modo de lectura
}

const RegionFilterSelect: React.FC<RegionFilterSelectProps> = ({
  filter,
  onFilterChange,
  visualizationTheme,
  readerMode,
}) => {
  const { selectedUnits, setSelectedUnits } = useGeographicSelection();
  const selectRef = useRef<any>(null); // Creamos una referencia para el Select
  const filtersScrollRef = useRef(null);
  useScrollMask(filtersScrollRef);

  // Ordenar las regiones seleccionadas utilizando el campo `order` de `regionsConfig`
  const selectedRegions = selectedUnits
    .filter((unit) => unit.type === "region")
    .sort((a, b) => regionsConfig[a.code].order - regionsConfig[b.code].order);

  // Función para obtener el nombre corto o largo de la región
  const getRegionName = (code: number, type: "short" | "long" = "short") => {
    const region = regionsConfig[code];
    return type === "short" ? region?.shortName : region?.longName;
  };

  // Función para obtener el color de la región
  const getRegionColor = (code: number) => {
    // return regionsConfig[code]?.color || "#000";
    return "#000";
  };

  // Función para eliminar una región
  const handleRemoveRegion = useCallback(
    (code: number) => {
      console.log("Eliminando región con código:", code);
      setSelectedUnits((prevUnits) => {
        console.log("Regiones anteriores:", prevUnits);

        const updatedRegions = prevUnits
          .filter((unit) => unit.type === "region" && unit.code !== code)
          .map((unit) => unit.code);

        console.log("Regiones actualizadas tras eliminar:", updatedRegions);

        // Llamamos a `onFilterChange` para actualizar la API con los nuevos valores
        onFilterChange(filter.key, updatedRegions);

        // Retornamos el nuevo estado actualizado
        const newState = [
          ...prevUnits.filter((unit) => unit.type !== "region"),
          ...updatedRegions.map((code) => ({ type: "region" as const, code })),
        ];

        console.log("Nuevo estado de selectedUnits:", newState);

        return newState;
      });
    },
    [setSelectedUnits, onFilterChange, filter.key]
  );

  // Función para añadir regiones
  const handleAddRegion = useCallback(
    (selectedOption: any) => {
      console.log("Añadiendo región seleccionada:", selectedOption);
      const newRegions = Array.isArray(selectedOption)
        ? selectedOption.map((option) => option.value)
        : [];

      console.log("Nuevas regiones a añadir:", newRegions);

      setSelectedUnits((prevUnits) => {
        console.log("Regiones anteriores:", prevUnits);

        // Obtener los códigos de las regiones ya seleccionadas
        const selectedRegionCodes = prevUnits
          .filter((unit) => unit.type === "region")
          .map((unit) => unit.code);

        // Combinamos las regiones anteriores con las nuevas
        const updatedRegionCodes = [
          ...new Set([...selectedRegionCodes, ...newRegions]), // Evitamos duplicados
        ];

        const updatedRegions = [
          ...prevUnits.filter((unit) => unit.type !== "region"),
          ...updatedRegionCodes.map((code) => ({
            type: "region" as const,
            code,
          })),
        ];

        console.log("Regiones actualizadas tras añadir:", updatedRegions);

        // Llamamos a `onFilterChange` para actualizar la API con los nuevos valores
        onFilterChange(filter.key, updatedRegionCodes);

        return updatedRegions;
      });
    },
    [setSelectedUnits, onFilterChange, filter.key]
  );

  return (
    <>
      <div
        ref={filtersScrollRef}
        className="flex-1 overflow-y-auto no-scrollbar"
      >
        {/* Lista de regiones seleccionadas */}
        <div className="w-full min-h-full h-auto flex flex-col justify-end xl:space-y-1.5 2xl:space-y-3">
          {selectedRegions.map((unit) => (
            <div key={unit.code} className="flex items-center">
              <h1
                className={
                  readerMode
                    ? "text-xl 2xl:text-2xl w-fit"
                    : "text-4xl 2xl:text-4xl w-fit"
                }
                // className="text-4xl 2xl:text-4xl w-fit"
                style={{
                  color: getRegionColor(unit.code), // Aplicamos el color según el código de la región
                }}
              >
                {getRegionName(unit.code, "short")}{" "}
                {/* Nombre corto de la región */}
              </h1>
              <IoIosClose
                size={20}
                className="hover:opacity-50 transition-opacity duration-300 cursor-pointer hidden-for-screenshot"
                onClick={() => handleRemoveRegion(unit.code)}
              />
            </div>
          ))}
          {selectedRegions.length === 0 && (
            <p
              className={
                readerMode ? "text-4xl 2xl:text-5xl" : "text-4xl 2xl:text-5xl"
              }
            >
              Chile
            </p>
          )}
        </div>
      </div>

      {selectedRegions.length < 16 && (
        <div className="h-auto flex hidden-for-screenshot">
          <div className="relative flex items-center justify-center space-x-2 pe-2 hover:text-black/30 transition-colors duration-300 cursor-pointer">
            <GoPlusCircle size={24} className="pointer-events-none" />
            <div
              className={
                readerMode
                  ? "text-xs pointer-events-none"
                  : "text-base pointer-events-none"
              }
            >
              {selectedRegions.length === 0
                ? "Ver Región"
                : "Comparar con otra Región"}
            </div>
            <Select
              ref={selectRef} // Asignamos la referencia al Select
              value={
                selectedRegions.length === 0
                  ? null
                  : selectedRegions.map((region) => ({
                      value: region.code,
                      label: getRegionName(region.code, "short"),
                    }))
              }
              options={filter.values.map((value) => ({
                value: Number(value.key),
                label: getRegionName(Number(value.key), "short") || value.label,
              }))}
              isMulti
              isSearchable={false}
              onChange={handleAddRegion}
              menuPlacement="top"
              components={{
                DropdownIndicator: null,
                IndicatorSeparator: null,
                ClearIndicator: undefined,
              }}
              noOptionsMessage={() => "No hay regiones disponibles"}
              styles={{
                container: (provided: any, state: any) => ({
                  ...provided,
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  left: 0,
                  marginLeft: "0!important",
                  top: 0,
                }),
                control: (provided: any, state: any) => ({
                  ...provided,
                  opacity: 0, // Ocultamos el control
                  borderRadius: "0", // Hacemos el Select circular
                  overflow: "hidden", // Ocultamos el contenido que se desborda
                  borderColor: "#000",
                  padding: 0,
                  margin: 0,
                  backgroundColor: "red",
                  minHeight: "unset",
                  minWidth: "unset",
                  cursor: "pointer",
                  width: "100%", // Ajustamos el tamaño para que sea pequeño y circular
                  height: "100%", // Tamaño del círculo
                }),
                valueContainer: (base) => ({
                  ...base,
                }),
                placeholder: (base) => ({
                  ...base,
                  display: "none", // Sin placeholder
                }),
                multiValue: (base) => ({
                  ...base,
                  display: "none", // Ocultamos los valores seleccionados
                }),
                menu: (base: any) => ({
                  ...base,
                  backgroundColor:
                    visualizationTheme?.backgroundColor || "#fff",
                  border: "1px solid #000",
                  borderRadius: "0 ",
                  color: "#000",
                  boxShadow: "none",
                  width: "max-content",
                  minWidth: "100%", // Puedes ajustar este tamaño según sea necesario
                }),
                option: (provided: any, state: any) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? "#000" : "transparent",
                  color: state.isFocused ? "#fff" : "#000",
                  "&:active": {
                    backgroundColor: "#000",
                    color: "#fff",
                  },
                }),
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RegionFilterSelect;
