// doc.tsx

import { StyleSpecification } from "mapbox-gl";
import * as turf from "@turf/turf";
import { GeoJSONData } from "src/types/geojson";
import { regionsConfig } from "src/config/regionsConfig";

export const defaultRegionStyle: StyleSpecification = {
  version: 8,
  sources: {},
  layers: [],
  glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
};
export const createTouristRegionStyle = (
  regionsData: GeoJSONData,
  sourceId: string = "geographic-units",
  fillLayerId: string = "geographic-units-fills",
  borderLayerId: string = "geographic-units-borders",
  mainColor: string = "#00FF7F"
): StyleSpecification => {
  // const mainColor = "#FFD700";
  // const mainColor = "#39FF14";
  // const mainColor = "#00FF7F";
  // const mainColor = "#7FFF00";
  // const mainColor = "#00FFCC";
  const style: StyleSpecification = {
    version: 8,
    sources: {},
    layers: [],
    glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
  };

  const addRegionLinesToStyle = (style: StyleSpecification) => {
    style.sources[sourceId] = {
      type: "geojson",
      data: regionsData,
    };

    style.layers.push({
      id: fillLayerId,
      type: "fill",
      source: sourceId,
      layout: {},
      paint: {
        "fill-color": [
          "case",
          [
            "all",
            ["boolean", ["feature-state", "selected"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          mainColor, // Hover color cuando está seleccionado
          ["boolean", ["feature-state", "selected"], false],
          "#FFF", // color de relleno para seleccionado
          ["boolean", ["feature-state", "hover"], false],
          mainColor, // color de relleno en hover si no está seleccionado
          "#000000", // sin color en estado normal
        ],
        "fill-opacity": [
          "case",
          [
            "all",
            ["boolean", ["feature-state", "selected"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          0.25, // Opacidad en hover cuando está seleccionado
          ["boolean", ["feature-state", "selected"], false],
          0, // Opacidad para seleccionados
          ["boolean", ["feature-state", "hover"], false],
          0.5, // Opacidad para hover
          0, // Sin opacidad por defecto
        ],
      },
    });

    style.layers.push({
      id: borderLayerId,
      type: "line",
      source: sourceId,
      layout: {},
      paint: {
        "line-color": [
          "case",
          [
            "all",
            ["boolean", ["feature-state", "selected"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          mainColor, // Borde diferente en hover cuando está seleccionado
          ["boolean", ["feature-state", "selected"], false],
          mainColor, // color de borde seleccionado
          ["boolean", ["feature-state", "hover"], false],
          mainColor, // color de borde en hover si no está seleccionado
          "#FFF", // color de borde por defecto
        ],
        // "line-opacity": [
        //   "case",
        //   ["boolean", ["feature-state", "selected"], false],
        //   1, // Opacidad total si está seleccionado
        //   0.3, // Opacidad normal
        // ],
        "line-opacity": [
          "case",
          [
            "all",
            ["boolean", ["feature-state", "selected"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          0.9, // Opacidad en hover cuando está seleccionado
          ["boolean", ["feature-state", "selected"], false],
          1, // Opacidad para seleccionados
          ["boolean", ["feature-state", "hover"], false],
          0.9, // Opacidad para hover
          0.5, // Sin opacidad por defecto
        ],
        "line-width": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          3, // Grosor de borde seleccionado
          1, // Grosor de borde por defecto
        ],
      },
    });
  };

  addRegionLinesToStyle(style);
  return style;
};

export const createLayerStyle = (
  data: GeoJSONData,
  sourceId: string,
  fillLayerId: string,
  borderLayerId: string
): StyleSpecification => {
  const mainColor = "#00FF7F"; // Color principal para hover y bordes
  const style: StyleSpecification = {
    version: 8,
    sources: {},
    layers: [],
    glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
  };

  const addRegionLinesToStyle = (style: StyleSpecification) => {
    // Definir la fuente geojson
    style.sources[sourceId] = {
      type: "geojson",
      data: data,
    };

    // Capa de relleno (fill)
    style.layers.push({
      id: fillLayerId,
      type: "fill",
      source: sourceId,
      layout: {},
      paint: {
        "fill-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          mainColor, // Color en hover
          "#fff", // Color por defecto (negro)
        ],
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.8, // Opacidad en hover
          0.8, // Opacidad por defecto
        ],
      },
    });

    // Capa de bordes (line)
    style.layers.push({
      id: borderLayerId,
      type: "line",
      source: sourceId,
      layout: {},
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          mainColor, // Color del borde en hover
          "#fff", // Color del borde por defecto (negro)
        ],
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.9, // Opacidad en hover
          1, // Opacidad por defecto
        ],
        "line-width": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          2, // Grosor del borde en hover
          1, // Grosor del borde por defecto
        ],
      },
    });
  };

  // Agregar las capas al estilo
  addRegionLinesToStyle(style);
  return style;
};
export const createAnalistRegionStyle = (
  regionsData: GeoJSONData
): StyleSpecification => {
  const style: StyleSpecification = {
    version: 8,
    sources: {},
    layers: [],
    glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
  };

  const mainColor = "#00FF7F";

  const addRegionLinesToStyle = (style: StyleSpecification) => {
    style.sources["geographic-units"] = {
      type: "geojson",
      data: regionsData,
    };

    style.layers.push({
      id: "geographic-units-fills",
      type: "fill",
      source: "geographic-units",
      layout: {},
      paint: {
        "fill-color": [
          "case",
          [
            "all",
            ["boolean", ["feature-state", "selected"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          mainColor, // Hover color cuando está seleccionado
          ["boolean", ["feature-state", "selected"], false],
          mainColor, // color de relleno para seleccionado
          ["boolean", ["feature-state", "hover"], false],
          mainColor, // color de relleno en hover si no está seleccionado
          "#000000", // sin color en estado normal
        ],
        "fill-opacity": [
          "case",
          [
            "all",
            ["boolean", ["feature-state", "selected"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          0.9, // Opacidad en hover cuando está seleccionado
          ["boolean", ["feature-state", "selected"], false],
          0.8, // Opacidad para seleccionados
          ["boolean", ["feature-state", "hover"], false],
          0.5, // Opacidad para hover
          0, // Sin opacidad por defecto
        ],
      },
    });

    style.layers.push({
      id: "geographic-units-borders",
      type: "line",
      source: "geographic-units",
      layout: {},
      paint: {
        "line-color": [
          "case",
          [
            "all",
            ["boolean", ["feature-state", "selected"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          mainColor, // Borde diferente en hover cuando está seleccionado
          ["boolean", ["feature-state", "selected"], false],
          "#FFF", // color de borde seleccionado
          "#FFF", // color de borde por defecto
        ],
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          1, // Opacidad total si está seleccionado
          0.75, // Opacidad normal
        ],
        "line-width": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          1.5, // Grosor de borde seleccionado
          1, // Grosor de borde por defecto
        ],
      },
    });
  };

  const addRegionLabelsToStyle = (style: StyleSpecification) => {
    const labelGeoJSON = JSON.parse(JSON.stringify(regionsData));

    // Preprocesar labelGeoJSON para mostrar solo el polígono más grande en MultiPolygon
    labelGeoJSON.features = labelGeoJSON.features.map((feature: any) => {
      if (feature.geometry.type === "MultiPolygon") {
        let largestPolygon: any = null;
        let maxArea = 0;

        feature.geometry.coordinates.forEach((polygonCoords: any) => {
          const polygon = turf.polygon(polygonCoords);
          const area = turf.area(polygon);
          if (area > maxArea) {
            largestPolygon = polygon;
            maxArea = area;
          }
        });

        if (largestPolygon) {
          feature.geometry = largestPolygon.geometry;
        }
      }

      if (regionsConfig[feature.properties.codregion]) {
        feature.properties.shortName =
          regionsConfig[feature.properties.codregion].shortName;
      }
      return feature;
    });

    style.sources["geographic-units-labels"] = {
      type: "geojson",
      data: labelGeoJSON,
    };

    style.layers.push({
      id: "geographic-units-labels-layer",
      type: "symbol",
      source: "geographic-units-labels",
      layout: {
        "text-field": ["get", "shortName"],
        "text-font": ["League Mono Regular", "Arial Unicode MS Regular"],
        "text-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          3,
          12, // En zoom 3 el tamaño del texto es 12
          7,
          22, // En zoom 7 el tamaño del texto es 22
        ],
        "text-offset": [0, 0],
        "text-padding": 1,
        "text-anchor": "top",
        "text-allow-overlap": false, // Evitar superposiciones
        "text-ignore-placement": false,
      },
      paint: {
        "text-color": "#FFFFFF",
        "text-halo-color": "#000000",
        "text-halo-width": 1,
      },
    });
  };

  // Llamamos a las funciones para construir el estilo
  addRegionLinesToStyle(style);
  addRegionLabelsToStyle(style);

  return style;
};
