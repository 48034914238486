// eslint-disable-next-line
import * as turf from "@turf/turf";
import { PaddingOptions } from "mapbox-gl";
import { GeoJSONData, GeoJSONFeature } from "src/types/geojson"; // Asegúrate de ajustar la ruta a tu tipo GeoJSONData

// Cache para almacenar los bounds calculados
const boundsCache: Record<string, [[number, number], [number, number]]> = {};

// Función para generar una clave única basada en los códigos de región
const generateCacheKey = (regionCodes: number[]) => {
  return regionCodes.sort().join("-"); // Sort para asegurarnos de que siempre se genera la misma clave sin importar el orden
};

// Función para calcular el área total de una geometría
const calculateLargestGeometry = (feature: GeoJSONFeature) => {
  if (feature.geometry.type === "Polygon") {
    // Si es un polígono, simplemente obtenemos las coordenadas
    return feature.geometry.coordinates;
  } else if (feature.geometry.type === "MultiPolygon") {
    // Si es un multipolígono, ordenamos por área y tomamos el más grande
    const geometries = feature.geometry.coordinates;

    const largestGeometry = geometries
      .map((geom) => ({
        geom,
        area: turf.area(turf.polygon(geom)),
      }))
      .sort((a, b) => b.area - a.area)[0]; // Obtener la geometría más grande

    return largestGeometry?.geom; // Devolvemos las coordenadas de la geometría más grande
  }

  // Si no es ni Polygon ni MultiPolygon, retornamos null (no es aplicable)
  return null;
};

// Función memoizada para calcular bounds de una o más regiones, ignorando geometrías pequeñas
export const calculateBoundsForRegions = (
  geojson: GeoJSONData,
  regionCodes: number[]
) => {
  const cacheKey = generateCacheKey(regionCodes);

  // Verificar si ya existe en el cache
  if (boundsCache[cacheKey]) {
    return boundsCache[cacheKey]; // Devolver el valor cacheado si existe
  }

  // Si no existe en el cache, calcular los bounds
  const selectedRegions = geojson.features.filter((feature) => {
    if (feature.properties === undefined || feature.properties === null) {
      return false;
    }
    return regionCodes.includes(feature.properties.codregion);
  });

  // Crear una colección de geometrías grandes, ignorando geometrías no aplicables
  const largestGeometries = selectedRegions
    .map((feature) => {
      const largestGeometry = calculateLargestGeometry(feature);
      return largestGeometry ? turf.polygon(largestGeometry) : null;
    })
    .filter((geometry) => geometry !== null); // Filtrar geometrías nulas

  // Combinar las geometrías grandes para calcular los bounds
  const combinedGeometry = turf.featureCollection(largestGeometries as any);
  const bbox = turf.bbox(combinedGeometry);
  const bounds: [[number, number], [number, number]] = [
    [bbox[0], bbox[1]], // Esquina inferior izquierda
    [bbox[2], bbox[3]], // Esquina superior derecha
  ];

  // Guardar el resultado en el cache
  boundsCache[cacheKey] = bounds;

  return bounds;
};

// Hacer zoom a una región específica por codregion
// export const zoomToRegionByCode = (
//   map: mapboxgl.Map,
//   geojson: GeoJSONData,
//   regionCode: number,
//   bearing: number // Añadimos el ángulo de rotación actual
// ) => {
//   const bounds = calculateBoundsForRegions(geojson, [regionCode]);

//   map.fitBounds(bounds as [mapboxgl.LngLatLike, mapboxgl.LngLatLike], {
//     padding: { top: 50, bottom: 50, left: 50, right: 50 },
//     maxZoom: 6,
//     duration: 1000,
//     bearing, // Aplicamos el ángulo de rotación
//   });
// };

// Hacer zoom a múltiples regiones por lista de codregion
export const zoomToMultipleRegionsByCode = (
  map: mapboxgl.Map,
  geojson: GeoJSONData,
  regionCodes: number[],
  bearing: number, // Añadimos el ángulo de rotación actual
  maxZoom: number = 6,
  centerInLeft: boolean = false
) => {
  const bounds = calculateBoundsForRegions(geojson, regionCodes);
  // map.showPadding = true;
  const mobilePadding: PaddingOptions = {
    top: 200,
    bottom: 100,
    left: 30,
    right: 30,
  };
  const tabletPadding: PaddingOptions = {
    top: 20,
    bottom: 20,
    left: 20,
    right: centerInLeft ? 450 : 20,
  };
  const desktopPadding: PaddingOptions = {
    top: 20,
    bottom: 20,
    left: 20,
    right: centerInLeft ? 650 : 20,
  };
  console.log("window.innerWidth", window.innerWidth);
  let padding: PaddingOptions;
  if (window.innerWidth < 768) {
    console.log("mobilePadding");
    padding = mobilePadding;
  } else if (window.innerWidth < 1280) {
    console.log("tabletPadding");
    padding = tabletPadding;
  } else {
    console.log("desktopPadding");
    padding = desktopPadding;
  }

  map.fitBounds(bounds as [mapboxgl.LngLatLike, mapboxgl.LngLatLike], {
    // padding: { top: 20, bottom: 20, left: 20, right: maxZoom === 7 ? 20 : 650 },
    padding: padding,
    maxZoom: maxZoom,
    // padding: { top: 50, bottom: 50, left: 50, right: 50 },
    duration: 1000,
    // preloadOnly: true,
    essential: true,
    bearing, // Aplicamos el ángulo de rotación
  });
  // setTimeout(() => {
  //   requestAnimationFrame(() => {
  //     map.setPadding({ top: 20, bottom: 20, left: 20, right: 20 });
  //   });
  // }, 2000);
};

// Hacer zoom out a todas las regiones del GeoJSON
export const zoomOutToAllRegions = (
  map: mapboxgl.Map,
  geojson: GeoJSONData,
  bearing: number // Añadimos el ángulo de rotación actual
) => {
  // Extraer todos los codregion de las features del geojson
  const allRegionCodes = geojson.features
    .map((feature) => feature.properties?.codregion)
    .filter((codregion) => codregion !== undefined); // Filtramos los que no tienen codregion

  // Usar calculateBoundsForRegions para calcular los bounds de todas las regiones
  const bounds = calculateBoundsForRegions(geojson, allRegionCodes as number[]);

  // Aplicar los bounds al mapa
  map.fitBounds(bounds as [mapboxgl.LngLatLike, mapboxgl.LngLatLike], {
    padding: { top: 20, bottom: 20, left: 20, right: 20 },
    maxZoom: 5,
    duration: 1000,
    bearing, // Aplicamos el ángulo de rotación
  });
};
