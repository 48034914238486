import React, { useRef } from "react";
import { Link } from "react-router-dom";
import {
  VisualizationCategory,
  VisualizationConfig,
} from "src/types/visualization";
import useScrollMask from "src/hooks/useScrollMask";

interface VisualizationListProps {
  category: VisualizationCategory; // Configuración de la categoría
  selectedVisualization: VisualizationConfig; // Configuración de la visualización seleccionada
}

const VisualizationListPanel: React.FC<VisualizationListProps> = ({
  category,
  selectedVisualization,
}) => {
  const scrollRef = useRef(null);

  useScrollMask(scrollRef);
  return (
    <div
      ref={scrollRef}
      className="flex-1 overflow-auto overflow-y-auto no-scrollbar"
    >
      <ul className="ps-4 list-none space-y-1 xl:space-y-2 h-full transition-opacity duration-300">
        {category.visualizations.map((visualization) => (
          <li key={visualization.key}>
            <Link
              to={`/analyst/analytics/${category.key}/${visualization.key}`}
              className={`tracking-tight block transition-all  ${
                selectedVisualization.key === visualization.key
                  ? "mb-2 xl:mb-5 text-2xl xl:text-3xl  font-medium font-normal duration-700 ease-in-out"
                  : "mb-0 text-sm xl:text-sm  origin-left text-gray-300 hover:text-[black!important] hover:opacity-90 hover:scale-105 duration-300"
              }`}
              style={{
                color:
                  selectedVisualization.key === visualization.key
                    ? category.theme.textColor
                    : category.theme.auxiliaryColor
                    ? category.theme.auxiliaryColor
                    : category.theme.textColor + "66",
              }}
            >
              <span className="underline underline-offset-4">
                {visualization.title}
              </span>
              {" /"}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VisualizationListPanel;
