// admin/modules/resources/pages/ResourcesPage.tsx
import React, { useEffect, useState } from "react";
import AdminLayout from "src/admin/layout/AdminLayout";
import AdminModal from "src/admin/shared/AdminModal";
import AlertModal from "src/admin/shared/AlertModal";

import Loader from "src/components/Loader";
import ResourceItem from "../components/ResourceItem";
import ResourceForm from "../components/ResourceForm";
import ResourceDetails from "../components/ResourceDetails";
import useResourceApi from "../hooks/useResourceApi";
import { Resource } from "../../../types/Resource";

type UserRole = "admin-full" | "admin-limited";

const ResourcesPage: React.FC = () => {
  const {
    resources,
    error,
    isLoading,
    fetchResources,
    createResource,
    updateResource,
    deleteResource,
    uploadFile,
  } = useResourceApi();

  const [selectedResource, setSelectedResource] =
    useState<Partial<Resource> | null>(null);
  const [modalType, setModalType] = useState<"view" | "create" | "edit" | null>(
    null
  );
  const [userRole] = useState<UserRole>("admin-limited");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertType, setAlertType] = useState<"success" | "warning" | "error">(
    "success"
  );
  const [alertMessage, setAlertMessage] = useState("");
  const [alertNote, setAlertNote] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    try {
      if (
        modalType === "create" &&
        userRole === "admin-full" &&
        selectedResource
      ) {
        await createResource(selectedResource);
      } else if (modalType === "edit" && selectedResource?.id) {
        await updateResource(selectedResource.id, selectedResource);
      }

      // Subir archivo si es necesario
      if (selectedResource?.access_type === "manual-input" && selectedFile) {
        await uploadFile(selectedFile, selectedResource.resource_key || "");
        setAlertType("success");
        setAlertMessage("Archivo subido con éxito.");
        setAlertNote(
          "Subir archivos manualmente puede tardar unos minutos en verse reflejado en la plataforma. Es posible que tengas que actualizar el historial para revisar."
        );
      } else {
        setAlertType("success");
        setAlertMessage("Recurso actualizado correctamente.");
        setAlertNote(undefined);
      }

      setIsAlertModalOpen(true);
    } catch (error) {
      setAlertType("error");
      setAlertMessage("Ocurrió un error al guardar el recurso.");
      setAlertNote(undefined);
      setIsAlertModalOpen(true);
    } finally {
      setModalType("view");
    }
  };

  const handleCancel = () => {
    setModalType("view");
  };

  const closeModal = () => {
    setSelectedResource(null);
    setModalType(null);
    setSelectedFile(null);
  };

  const CATEGORY_CONFIG: { [key: string]: string[] } = {
    "Estadísticas Territoriales": [
      "attractions",
      "national_destinations",
      "territorial_by_commune",
    ],
    "Estadísticas de Flujos Turísticos": [
      "foreign_tourist_arrivals",
      "total_overnight_tourism",
      "occasional_overnight_tourism",
      "departures_of_chilean_residents",
      "passengers_transported_on_flights",
      "historical_cruise",
      "snapse_visitors",
      "emat",
    ],
    "Estadísticas de la Oferta": [
      "act_employment",
      "act_company_by_commune",
      "act_company_by_size",
    ],
    "Estadísticas Económicas": [
      "pib_regional",
      "ipc",
      "tourist_foreign_currency_income",
      "tourist_foreign_currency_outflow",
    ],
  };

  // Función para agrupar recursos en categorías
  const categorizeResources = (resources: Resource[]) => {
    const categories: { [key: string]: Resource[] } = { global: [] };

    Object.keys(CATEGORY_CONFIG).forEach((category) => {
      categories[category] = [];
    });

    resources.forEach((resource) => {
      let categorized = false;
      for (const [category, keys] of Object.entries(CATEGORY_CONFIG)) {
        if (keys.includes(resource.resource_key)) {
          categories[category].push(resource);
          categorized = true;
          break;
        }
      }
      if (!categorized) {
        categories.global.push(resource);
      }
    });

    // Ordena los recursos en cada categoría según el orden de las claves en CATEGORY_CONFIG
    Object.entries(CATEGORY_CONFIG).forEach(([category, keys]) => {
      categories[category].sort(
        (a, b) => keys.indexOf(a.resource_key) - keys.indexOf(b.resource_key)
      );
    });

    return categories;
  };
  const categories = categorizeResources(resources || []);

  return (
    <>
      <AdminLayout
        header="Gestión de Recursos"
        message="Aquí puedes gestionar los recursos: crear, editar o eliminar."
      >
        {isLoading && <Loader color="black"></Loader>}
        {error && <p className="text-center text-red-500 mb-4">{error}</p>}
        <div className="space-y-14">
          {categories.global.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {categories.global.map((resource) => (
                <ResourceItem
                  key={resource.id}
                  resource={resource}
                  onView={(resource) => {
                    setSelectedResource(resource);
                    setModalType("view");
                  }}
                />
              ))}
            </div>
          )}

          {/* Renderiza categorías con encabezados */}
          {Object.entries(categories).map(([category, items]) => {
            if (category === "global" || items.length === 0) return null;
            return (
              <div key={category} className="space-y-2">
                <h2 className="text-xl font-bold">{category}</h2>
                <hr />
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {items.map((resource) => (
                    <ResourceItem
                      key={resource.id}
                      resource={resource}
                      onView={(resource) => {
                        setSelectedResource(resource);
                        setModalType("view");
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}

          {userRole === "admin-full" && (
            <button
              className="bg-black text-white rounded-full py-2 px-4 mx-auto flex hover:bg-gray-800"
              onClick={() => {
                setSelectedResource({});
                setModalType("create");
              }}
            >
              + Crear Nuevo Recurso
            </button>
          )}
        </div>
      </AdminLayout>

      <AdminModal
        isOpen={modalType !== null}
        onClose={closeModal}
        title={
          modalType === "view"
            ? "Detalles del Recurso"
            : modalType === "create"
            ? "Crear Nuevo Recurso"
            : "Editar Recurso"
        }
      >
        {modalType === "view" && selectedResource && (
          <ResourceDetails
            resource={selectedResource}
            userRole={userRole}
            onDelete={async () => {
              if (selectedResource.id) {
                await deleteResource(selectedResource.id);
                closeModal();
              }
            }}
            onEdit={() => setModalType("edit")}
          />
        )}
        {(modalType === "create" || modalType === "edit") &&
          selectedResource && (
            <ResourceForm
              resource={selectedResource}
              onChange={setSelectedResource}
              onSave={handleSave}
              onCancel={handleCancel}
              isEditing={modalType === "edit"}
              userRole={userRole}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          )}
        {isLoading && (
          <div className="inset-0 absolute w-full h-full flex items-center justify-center bg-white/80 backdrop-blur">
            <Loader color="black" size="6rem" />
          </div>
        )}
      </AdminModal>

      <AlertModal
        isOpen={isAlertModalOpen}
        onClose={() => setIsAlertModalOpen(false)}
        type={alertType}
        message={alertMessage}
        note={alertNote} // Usar el nuevo parámetro opcional
      />
    </>
  );
};

export default ResourcesPage;
