import React, { useState, useEffect, useCallback } from "react";
import ChartRenderer from "src/components/charts/ChartRenderer";
import FilterSelect from "src/components/charts/FilterSelect";
import MetricToggle from "src/components/charts/MetricToggle";
import Loader from "src/components/Loader";
import { VisualizationTheme } from "src/types/visualization";
import {
  AvailableFilter,
  GraphDataResponse,
  GraphViewMode,
} from "src/types/graph";
import ViewModeSwitch from "src/components/charts/ViewModeSwitch";
import { PiSmileySad } from "react-icons/pi";
import FullscreenLayout from "src/layouts/FullscreenLayout";
import axios from "axios";
import { buildFiltersBody, updateFilters } from "src/utils/filterUtils";
import DataSource from "src/components/charts/DataSource";

const visualizationTheme: VisualizationTheme = {
  backgroundColor: "#fff",
  textColor: "black",
};
const PredictViewer: React.FC = () => {
  const [data, setData] = useState<any>(null); // Ajustar el tipo si tienes uno más específico
  const [filters, setFilters] = useState<AvailableFilter[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [viewMode, setViewMode] = useState<GraphViewMode>("line");

  // Función para obtener los datos usando axios
  const endpoint = "/api/graph/regional_migration_projections";
  const source = {
    name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR, en base a modelos de proyección econométricos que consideran los datos proporcionados por el Big Data para el Turismo Interno.",
  };

  // Fetch de los datos inicial
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const regionFilters: Record<string, any> = {};

        const responseData = await axios.post(endpoint, regionFilters);
        setData(responseData.data);
        setFilters(responseData.data.availableFilters);
        setError(null);
      } catch (err) {
        // Puedes acceder al mensaje de error personalizado
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Error al obtener datos");
        }
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleFilterChange = useCallback(
    async (
      filterKey: string,
      selected: string | number | Array<string | number> | null
    ) => {
      if (!filters) return;

      const newFilters = updateFilters(filters, filterKey, selected);
      setFilters(newFilters);
      setIsFilterLoading(true);
      try {
        const response = await axios.post<GraphDataResponse>(
          endpoint,
          buildFiltersBody(newFilters)
        );
        setData(response.data);
        setFilters(response.data.availableFilters);
        setIsFilterLoading(false);
      } catch (error) {
        console.error("Error fetching filtered attractions", error);
      }
    },
    [filters, endpoint]
  );

  const metricFilter = filters?.find(
    (filter) =>
      filter.key === "metric" &&
      filter.values.length === 2 &&
      filter.values[1].key === "annual_variation"
  );
  const graphFilters = filters?.filter(
    (filter) =>
      !(
        filter.key === "metric" &&
        filter.values.length === 2 &&
        filter.values[1].key === "annual_variation"
      ) && filter.key !== "region_codes"
  );

  return (
    <FullscreenLayout header={data ? data.metadata.title : ""}>
      {isLoading && <Loader color={visualizationTheme.textColor} />}
      {error && (
        <div className="inset-0 absolute w-full h-full flex justify-center items-center">
          <div className="p-3 text-2xl text-white border border-white bg-red-500 flex space-x-4 items-center">
            <p>{error}</p>
            <PiSmileySad />
          </div>
        </div>
      )}
      {data && (
        <div className="space-y-4">
          <div className=" sm:flex justify-between items-start space-y-4 sm:space-y-0">
            <div className="flex space-x-2">
              <ViewModeSwitch
                viewMode={viewMode}
                onChange={setViewMode}
                availableCharts={["line", "bar", "table"]}
                defaultChart={"line"}
              />

              {metricFilter && metricFilter.values.length === 2 && (
                <MetricToggle
                  filter={metricFilter}
                  onFilterChange={handleFilterChange}
                  key="metric-toggle"
                />
              )}

              {isFilterLoading && (
                <div className="h-8 w-8 relative">
                  <Loader size="1.25rem" color="#000" />
                </div>
              )}
            </div>
            <div className="flex flex-wrap justify-start sm:justify-end gap-2">
              {graphFilters?.map((filter) => (
                <FilterSelect
                  key={filter.key}
                  filter={filter}
                  onFilterChange={handleFilterChange}
                  theme={visualizationTheme}
                />
              ))}
            </div>
          </div>
          <div className="graph-container relative">
            {data ? <ChartRenderer viewMode={viewMode} data={data} /> : null}
          </div>
          <DataSource source={source} />

          <div className="text-justify p-3">
            <p className="text-xs text-gray-500 font-sans">
              {/* {visualizationConfig.description} */}
              <li>
                Viajes turísticos totales: El viaje es realizado con una
                frecuencia máxima de 3 veces al mismo destino principal durante
                el mes de análisis.
              </li>
              <li>
                Proyecciones realizadas mediante modelos econométricos Sarimax,
                considerando efecto estallido social y efecto pandemia por
              </li>
              <li>
                Covid-19. Proyecciones mensuales regionales y comunales basadas
                en estructuras de último dato real de cada mes.
              </li>
            </p>
          </div>
        </div>
      )}
    </FullscreenLayout>
  );
};

export default PredictViewer;
