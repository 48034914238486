import React, { useEffect } from "react";
import { Resource, ResourceAccessLabels } from "../../../types/Resource";
import {
  AiOutlineCloudUpload,
  AiOutlineDownload,
  AiOutlineLink,
  AiOutlineSearch,
} from "react-icons/ai";
import UpdateHistory from "./UpdateHistory";
import useResourceApi from "../hooks/useResourceApi";

import { FaSpinner } from "react-icons/fa";
interface ResourceDetailsProps {
  resource: Partial<Resource>;
  userRole: "admin-full" | "admin-limited";
  onEdit: () => void;
  onDelete: () => void;
}

// Función para obtener el ícono y la etiqueta según el tipo de acceso
const getAccessTypeInfo = (accessType: string | undefined) => {
  if (!accessType) return { icon: null, label: "Desconocido" };

  switch (accessType) {
    case "manual-input":
      return {
        icon: <AiOutlineCloudUpload size={20} className="text-blue-500" />,
        label: ResourceAccessLabels[accessType].labelShort,
      };
    case "direct-link":
      return {
        icon: <AiOutlineLink size={20} className="text-green-500" />,
        label: ResourceAccessLabels[accessType].labelShort,
      };
    case "site-link-search":
    case "site-row-search":
      return {
        icon: <AiOutlineSearch size={20} className="text-yellow-500" />,
        label: ResourceAccessLabels[accessType].labelShort,
      };
    default:
      return { icon: null, label: "Desconocido" };
  }
};

const ResourceDetails: React.FC<ResourceDetailsProps> = ({
  resource,
  userRole,
  onEdit,
  onDelete,
}) => {
  const { logs, isLoading, fetchLogs, downloadFile } = useResourceApi();
  const { icon, label } = getAccessTypeInfo(resource.access_type);

  useEffect(() => {
    if (resource.resource_key) {
      fetchLogs(resource.resource_key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource.resource_key]);

  const handleDownload = async () => {
    if (resource.resource_key && resource.name) {
      await downloadFile(resource.resource_key, resource.name);
    }
  };

  return (
    <div className="resource-details flex portrait:flex-col h-full landscape:space-x-8">
      <div className="flex-1 p-6 landscape:overflow-auto portrait:h-min flex flex-col justify-between select-text">
        <div>
          <div className="mb-4">
            <h3 className="text-2xl font-bold">
              {resource.name || "Sin título"}
            </h3>
            <div className="text-sm text-gray-500 mb-4 italic">
              <span>{resource.resource_key}</span>
            </div>
            <div className="flex items-center text-sm text-gray-500 mt-1 space-x-2">
              {icon}
              <span>{label}</span>
            </div>
          </div>

          {resource.url && (
            <p className="text-sm mb-4">
              <strong>URL:</strong>{" "}
              <a
                href={resource.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                {resource.url}
              </a>
            </p>
          )}
          {resource.search_term && (
            <p className="text-sm">
              <strong>Término de Búsqueda:</strong> {resource.search_term}
            </p>
          )}
        </div>

        <div className="mt-6 flex space-x-4 justify-start">
          <button
            className="text-white py-2 px-4 rounded-full bg-black hover:bg-gray-800 transition"
            onClick={onEdit}
          >
            Editar
          </button>
          {userRole === "admin-full" && (
            <button
              className="bg-red-500 text-white py-2 px-4 rounded-full hover:bg-red-600 transition"
              onClick={onDelete}
            >
              Eliminar
            </button>
          )}
          {resource.status === "success" && (
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition flex items-center"
              onClick={handleDownload}
              disabled={isLoading} // Deshabilita el botón mientras está cargando
            >
              {isLoading ? (
                <FaSpinner className="animate-spin mr-2" size={20} />
              ) : (
                <AiOutlineDownload className="mr-2" size={20} />
              )}
              {isLoading ? "Descargando..." : "Descargar"}
            </button>
          )}
        </div>
      </div>

      <div className="w-px portrait:hidden bg-gray-300"></div>

      <UpdateHistory
        updates={logs}
        fetchLogs={() => fetchLogs(resource.resource_key!)}
      />
    </div>
  );
};

export default ResourceDetails;
